/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Button, Typography } from "@mui/material";
import theme from "../../theme";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddressForm from "../AddressForm";

const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const AddressesContainer = ({
  activeStep,
  setActiveStep,
  editMode,
  setEditMode,
  selectedAddress,
  setSelectedAddress,
  addressIndex,
  setAddressIndex,
}) => {
  const [addresses, setAddresses] = useState([]);
  const {
    user: { user },
  } = useSelector((state) => state);

  useEffect(() => {
    if (user?.addresses?.length > 0) {
      setAddresses(user.addresses);
    }
  }, [user]);

  return (
    <>
      {editMode ? (
        <AddressForm
          setEditMode={setEditMode}
          selectedAddress={selectedAddress}
          addressIndex={addressIndex}
        />
      ) : (
        <>
          {addresses?.map((oAddress, iIndex) => (
            <Stack
              key={iIndex}
              width="100%"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              sx={{
                border: "2px dashed grey",
                padding: "10px",
                marginBottom: "10px",
                flexDirection: { xs: "column", md: "row" },
                rowGap: "10px",
              }}
            >
              <Stack>
                <Typography
                  sx={{
                    fontSize: "18px",
                  }}
                >
                  <span
                    style={{ fontWeight: "bold" }}
                  >{`${oAddress.first_name} ${oAddress.last_name}`}</span>{" "}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "18px",
                  }}
                >{`${oAddress.address}, ${oAddress.city}, ${oAddress.pin_code}`}</Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>MOBILE: </span>
                  {`${oAddress?.mobile.substring(3)}`}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                  }}
                >
                  TIME SLOT:&nbsp;
                  {`${oAddress?.time_slot}`}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  width: "100%",
                  flexDirection: "column",
                  rowGap: "10px",
                  justifyContent: "space-between",
                  alignItems: { xs: "center", md: "flex-end" },
                }}
              >
                <Button
                  onClick={() => {
                    setAddressIndex(iIndex);
                    setEditMode(true);
                    let oAddress = Object.assign({}, addresses[iIndex]);
                    oAddress.mobile = oAddress.mobile.substring(3);
                    setSelectedAddress(oAddress);
                  }}
                  sx={{
                    ...stepperButtonStyles,
                  }}
                >
                  EDIT
                </Button>
                {setActiveStep && (
                  <Button
                    sx={{
                      ...stepperButtonStyles,
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      setActiveStep((previousState) => previousState + 1);
                      setAddressIndex(iIndex);
                      setSelectedAddress(addresses[iIndex]);
                    }}
                  >
                    DELIVER HERE
                  </Button>
                )}
              </Stack>
            </Stack>
          ))}
        </>
      )}
    </>
  );
};

export default AddressesContainer;
