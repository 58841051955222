/* eslint-disable array-callback-return */
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Slide,
  Typography,
  Grid,
  IconButton,
  Stack,
  Box,
  InputLabel,
  MenuItem,
  DialogTitle,
  DialogActions,
  DialogContentText,
  CircularProgress,
} from "@mui/material";
import theme from "../../theme";
import CloseIcon from "@mui/icons-material/Close";
import { Field, Form, Formik, setNestedObjectValues } from "formik";
import { TextField, Select } from "formik-mui";
import * as yup from "yup";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";

import { db } from "../../firebase";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const inputContainerStyles = {
  height: "90px",
  width: "240px",
};

const inputLabelStyles = {
  color: "#000000",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: theme.fontWeight.semiBold,
  marginBottom: "10px",
};

const inputStyles = {
  "& .MuiInputBase-root": {
    borderRadius: "10px",
    width: "250px",
  },

  "& .MuiFormHelperText-root": {
    margin: "3px 0px 0px 5px",
    width: "250px",
  },

  "& .MuiInputBase-input": {
    fontSize: { sm: "13px" },
    fontWeight: { sm: theme.fontWeight.regular },
  },
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  first_name: yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Invalid name")
    .min(2, "Min. 2 characters required")
    .required("Name is required"),
  address: yup.string().required("Address is required"),
  time_slot: yup.string().required("Time slot is required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Invalid numberf")
    .max(10, "Invalid number")
    .min(2, "Invalid number")
    .required("Mobile is required"),
});

const BookAppointment = ({ openBookingDialog, setOpenBookingDialog }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [message, setMessage] = useState("");
  const [pinError, setPinError] = useState("");
  const [initialFormValues] = useState({
    first_name: "",
    address: "",
    city: "",
    pin_code: "",
    phone: "",
    time_slot: "",
  });
  const [loading, setLoading] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const returnErrorMessage = (fieldName, value) => {
    let errorMessage = "";
    if (fieldName === "pin_code") {
      if (!value) {
        errorMessage = "Pin code is required";
      } else if (value.toString().length !== 6) {
        errorMessage = "Invalid pin code";
      } else if (!(394101 <= parseInt(value) && parseInt(value) <= 396510)) {
        errorMessage = "Only Surat pin code are serviceable";
      }
    }
    return errorMessage;
  };

  const validateField = (fieldName, value, callback) => {
    let isValid = true;
    const error = returnErrorMessage(fieldName, value);
    if (error) {
      callback(error);
      isValid = false;
    }

    return isValid;
  };

  const checkIfAppointmentExist = async (mobile) => {
    const sRef = collection(db, "appointments");
    const q = query(sRef, where("mobile", "==", "+91" + mobile));
    const querySnapshot = await getDocs(q);

    let aAppointments = [];
    querySnapshot.forEach((doc) => {
      doc.data().status.map((oStatus) => {
        if (oStatus.id === "Completed" && oStatus.created_on === "") {
          aAppointments.push(doc.data());
        }
      });
    });

    return aAppointments.length > 0;
  };

  const handleSubmitForm = async (values) => {
    const bExist = await checkIfAppointmentExist(values.phone);

    if (bExist) {
      setDialogTitle("");
      setMessage(
        `Appointment is already booked with mobile ${values.phone}. Our executive
        will be at your doorstep within in 24 to 48 hrs.`
      );
      setOpenBookingDialog(false);
      setOpenDialog(true);
      setLoading(false);
      return;
    }

    let aName = values.first_name.split(" ");
    let sFirstName = "";
    let sLastName = "";
    if (aName.length > 1) {
      sFirstName = aName[0];
      sLastName = aName[1];
    } else {
      sFirstName = values.first_name;
    }
    addDoc(collection(db, "appointments"), {
      mobile: "+91" + values.phone,
      created_on: new Date().toString(),
      last_modified: new Date().toString(),
      user_address: {
        address: values.address,
        pin_code: values.pin_code,
        time_slot: values.time_slot,
        first_name: sFirstName,
        last_name: sLastName,
      },
      status_text: "Scheduled",
      status: [
        {
          id: "Scheduled",
          created_on: new Date().toString(),
          message: "Appointment booked without order",
        },
        {
          id: "Completed",
          created_on: "",
          message: "",
        },
      ],
    })
      .then((result) => {
        setDialogTitle("Success");
        setMessage(
          `Appointment booked successfully. Our executive
        will be at your doorstep within in 24 to 48 hrs.`
        );
        setOpenBookingDialog(false);
        setOpenDialog(true);
        setLoading(false);
      })
      .catch((error) => {
        setDialogTitle("Error");
        setMessage(
          `Error while booking appointment. Please try after sometime`
        );
        setOpenDialog(true);
        setLoading(false);
      });
  };

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        open={openBookingDialog}
        onClose={() => {}}
      >
        <DialogContent>
          <Grid
            sx={{
              flexDirection: "column",
            }}
          >
            <Grid item key={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: theme.fontWeight.bold,
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    FREE APPOINTMENT BOOKING
                  </Typography>
                  <Typography
                    fontWeight={theme.fontWeight.light}
                    fontSize="14px"
                    sx={{
                      fontSize: { xs: "12px", md: "16px" },
                    }}
                  >
                    Our executive will be at your doorstep with fabrics samples
                    for look and feel
                  </Typography>
                </Box>
                <IconButton
                  onClick={() => {
                    setOpenBookingDialog(false);
                  }}
                  sx={{
                    borderRadius: "56px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "13px",
                    fontWeight: theme.fontWeight.semiBold,
                    textAlign: "center",
                    textTransform: "none",
                    marginBottom: "60px",
                    padding: "0px",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Grid>
            <Grid item key={2}>
              <Stack direction="row" flexWrap="wrap" alignItems="center">
                <Formik
                  initialValues={initialFormValues}
                  onSubmit={() => {}}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                >
                  {({ values, validateForm, setTouched }) => {
                    return (
                      <Form
                        id="bookAppointmentForm"
                        noValidate
                        onSubmit={(e) => {
                          setLoading(true);
                          e.preventDefault();
                          validateForm(values).then((error) => {
                            const isPinValid = validateField(
                              "pin_code",
                              values.pin_code,
                              (error) => {
                                setPinError(error);
                              }
                            );
                            if (isPinValid) {
                              setPinError("");
                            }
                            if (Object.keys(error).length > 0 || !isPinValid) {
                              setTouched(setNestedObjectValues(error, true));
                              setLoading(false);
                            } else {
                              handleSubmitForm(values);
                            }
                          });
                        }}
                      >
                        <Stack
                          direction="row"
                          rowGap="10px"
                          flexWrap="wrap"
                          justifyContent="start"
                          sx={{
                            columnGap: { xs: "10px", lg: "25px", xl: "54px" },
                          }}
                        >
                          <Box sx={{ ...inputContainerStyles }}>
                            <InputLabel
                              htmlFor="first_name"
                              sx={{ ...inputLabelStyles }}
                            >
                              NAME:
                            </InputLabel>
                            <Field
                              component={TextField}
                              type="text"
                              name="first_name"
                              id="first_name"
                              sx={{ ...inputStyles }}
                              disabled={false}
                            />
                          </Box>
                          <Box sx={{ ...inputContainerStyles }}>
                            <InputLabel
                              htmlFor="phone"
                              sx={{ ...inputLabelStyles }}
                            >
                              MOBILE (+91):
                            </InputLabel>
                            <Field
                              component={TextField}
                              type="number"
                              name="phone"
                              id="phone"
                              sx={{ ...inputStyles }}
                            />
                          </Box>

                          <Box sx={{ ...inputContainerStyles }}>
                            <InputLabel
                              htmlFor="address"
                              sx={{ ...inputLabelStyles }}
                            >
                              ADDRESS:
                            </InputLabel>
                            <Field
                              component={TextField}
                              type="text"
                              name="address"
                              id="address"
                              sx={{ ...inputStyles }}
                              disabled={false}
                            />
                          </Box>

                          <Box sx={{ ...inputContainerStyles }}>
                            <InputLabel
                              htmlFor="pin_code"
                              sx={{ ...inputLabelStyles }}
                            >
                              PIN CODE:
                            </InputLabel>

                            <Field
                              component={TextField}
                              type="number"
                              name="pin_code"
                              id="pin_code"
                              disabled={false}
                              sx={{
                                ...inputStyles,
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: `${pinError && "#d32f2f"}`,
                                },
                                "& .MuiFormHelperText-root": {
                                  color: "#d32f2f",
                                },
                              }}
                              helperText={pinError && pinError}
                            />
                          </Box>

                          <Box sx={{ ...inputContainerStyles }}>
                            <InputLabel
                              htmlFor="time_slot"
                              sx={{ ...inputLabelStyles }}
                            >
                              PREFERRED TIME SLOT:
                            </InputLabel>
                            <Field
                              component={Select}
                              type="text"
                              name="time_slot"
                              id="time_slot"
                              sx={{ width: "250px", borderRadius: "10px" }}
                              disabled={false}
                            >
                              <MenuItem value="9AM - 3PM">9AM - 3PM</MenuItem>
                              <MenuItem value="3PM - 9PM">3PM - 9PM</MenuItem>
                            </Field>
                          </Box>
                        </Stack>
                      </Form>
                    );
                  }}
                </Formik>
              </Stack>
            </Grid>
            <Grid
              item
              sx={{
                padding: "20px 0",
              }}
              key={3}
            >
              <Button
                disabled={loading}
                type="submit"
                form="bookAppointmentForm"
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "black",
                  color: "white",
                  fontSize: "18px",
                  fontWeight: theme.fontWeight.semiBold,
                  textAlign: "center",
                  height: "49px",
                  width: "203px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "black",
                  },
                }}
              >
                BOOK APPOINTMENT
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              sx={{
                color: "black",
              }}
              fontWeight={theme.fontWeight.regular}
              fontSize="18px"
            >
              {message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              ...stepperButtonStyles,
            }}
            onClick={handleDialogClose}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BookAppointment;
