import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  user: null,
  loading: false
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLoading(state, actions) {
      state.loading = actions.payload
    },
    setUser(state, actions) {
      state.user = actions.payload
    }
  }
})

export default userSlice.reducer
export const { setUser, setUserLoading } = userSlice.actions
