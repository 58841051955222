/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useSelector } from "react-redux";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import {
  Box,
  InputLabel,
  Stack,
  Typography,
  Snackbar,
  Alert as MuiAlert,
  MenuItem,
} from "@mui/material";
import theme from "../../theme";
import { Field, Form, Formik, setNestedObjectValues } from "formik";
import { TextField, Select } from "formik-mui";
import { ScrollToTop } from "../../components";
import { useAppDispatch } from "../../app/store";
import { setUserLoading } from "../../features/user/userSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const inputContainerStyles = {
  height: "90px",
};

const inputLabelStyles = {
  color: "#000000",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: theme.fontWeight.semiBold,
  marginBottom: "12px",
};

const inputStyles = {
  "& .MuiInputBase-root": {
    borderRadius: "0px",
    width: "350px",
  },

  "& .MuiFormHelperText-root": {
    margin: "5px 0px 0px 5px",
    width: "300px",
  },

  "& .MuiInputBase-input": {
    fontSize: { sm: "13px" },
    fontWeight: { sm: theme.fontWeight.regular },
  },
};

const validationSchema = yup.object({
  first_name: yup
    .string()
    .trim()
    .matches(/^[\p{L}\p{M}-]+$/u, "First name cannot contain a number")
    .min(2, "First name should be of minimum 2 characters length")
    .required("First name is required"),
  last_name: yup
    .string()
    .trim()
    .matches(/^[\p{L}\p{M}-]+$/u, "Last name cannot contain a number")
    .min(2, "Last name should be of minimum 2 characters length")
    .required("Last name is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  time_slot: yup.string().required("Time slot is required"),
});

const AddressAndDelivery = ({ setActiveStep, showMeasurementsInfo = true }) => {
  const {
    user: { user },
    currentUser,
  } = useSelector((state) => state);
  const [pinError, setPinError] = useState("");

  const [initialFormValues, setInitialFormValues] = useState({
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    pin_code: "",
    email: "",
    phone: "",
    time_slot: "",
  });

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const dispatch = useAppDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    if (currentUser.currentUser) {
      const oAddress = Object.assign({}, user.address);
      oAddress.phone = user.mobile.substring(3);
      oAddress.email = user.email;
      setInitialFormValues(oAddress);
    }
  }, []);

  const returnErrorMessage = (fieldName, value) => {
    let errorMessage = "";
    if (fieldName === "phone") {
      if (!value) {
        errorMessage = "Mobile is required";
      } else if (value.toString().length !== 10) {
        errorMessage = "Enter a valid mobile";
      }
    } else if (fieldName === "pin_code") {
      if (!value) {
        errorMessage = "Pin code is required";
      } else if (value.toString().length !== 6) {
        errorMessage = "Invalid pin code";
      } else if (!(394101 <= parseInt(value) && parseInt(value) <= 396510)) {
        errorMessage = "Delivery not available";
      }
    }
    return errorMessage;
  };

  const validateField = (fieldName, value, callback) => {
    let isValid = true;
    const error = returnErrorMessage(fieldName, value);
    if (error) {
      callback(error);
      isValid = false;
    }

    return isValid;
  };

  const checkEmailExists = async (currentUserMobile, email) => {
    const ordersRef = collection(db, "users");
    const q = query(ordersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    let bFound = false;
    querySnapshot.forEach((doc) => {
      const oData = doc.data();
      if (oData.mobile !== currentUserMobile) {
        bFound = true;
      }
    });
    return bFound;
  };

  const handleSubmitForm = async (values) => {
    if (currentUser.currentUser) {
      dispatch(setUserLoading(true));

      const emailExist = await checkEmailExists(
        currentUser.currentUser.mobile,
        values.email
      );

      if (emailExist) {
        setAlertMessage(
          "Email is already associated with different account. Please choose a different email."
        );
        setAlertSeverity("error");
        setOpenAlert(true);
        dispatch(setUserLoading(false));
        return;
      }

      const docRef = doc(db, "users", currentUser.currentUser.mobile);
      await updateDoc(docRef, {
        email: values.email,
        last_modified: new Date().toString(),
        address: {
          first_name: values.first_name,
          last_name: values.last_name,
          address: values.address,
          city: values.city,
          pin_code: values.pin_code.toString(),
          time_slot: values.time_slot,
        },
      });
      dispatch(setUserLoading(false));
    }
    setActiveStep((previousState) => previousState + 1);
  };

  return (
    <>
      <ScrollToTop id="Addrress" />
      <Box
        component="span"
        sx={{
          p: 2,
          border: "1px dashed grey",
          marginBottom: "20px",
          maxWidth: "400px",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Important:
        </Typography>
        <Typography
          fontSize="16px"
          fontWeight={theme.fontWeight.regular}
          textAlign="left"
        >
          We are serviceable only in{" "}
          <span style={{ fontWeight: "bold" }}>Surat</span> right now.
        </Typography>
      </Box>
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="flex-start"
        rowGap="60px"
        sx={{
          justifyContent: { xs: "start", lg1300: "space-between" },
          columnGap: { xs: "10px", lg: "25px", xl: "54px" },
        }}
      >
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{ width: "100%", maxWidth: { xs: "725px", xl: "800px" } }}
        >
          <Formik
            initialValues={initialFormValues}
            onSubmit={() => {}}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ values, validateForm, setTouched }) => {
              return (
                <Form
                  id="addressAndDeliveryForm"
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    validateForm(values).then((error) => {
                      const isPinValid = validateField(
                        "pin_code",
                        values.pin_code,
                        (error) => {
                          setPinError(error);
                        }
                      );

                      if (isPinValid) {
                        setPinError("");
                      }

                      if (Object.keys(error).length > 0 || !isPinValid) {
                        setTouched(setNestedObjectValues(error, true));
                      } else {
                        handleSubmitForm(values);
                      }
                    });
                  }}
                >
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="start"
                    sx={{ gap: { xs: "20px 25px", xl: "15px 87px" } }}
                  >
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="first_name"
                        sx={{ ...inputLabelStyles }}
                      >
                        First Name:
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="first_name"
                        id="first_name"
                        sx={{ ...inputStyles }}
                        disabled={false}
                      />
                    </Box>

                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="last_name"
                        sx={{ ...inputLabelStyles }}
                      >
                        Last Name:
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="last_name"
                        id="last_name"
                        sx={{ ...inputStyles }}
                        disabled={false}
                      />
                    </Box>

                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="address"
                        sx={{ ...inputLabelStyles }}
                      >
                        Address:
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="address"
                        id="address"
                        sx={{ ...inputStyles }}
                        disabled={false}
                      />
                    </Box>

                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel htmlFor="city" sx={{ ...inputLabelStyles }}>
                        City:
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="city"
                        id="city"
                        sx={{ ...inputStyles }}
                        disabled={false}
                      />
                    </Box>

                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="pin_code"
                        sx={{ ...inputLabelStyles }}
                      >
                        Pin Code:
                      </InputLabel>

                      <Field
                        component={TextField}
                        type="number"
                        name="pin_code"
                        id="pin_code"
                        disabled={false}
                        sx={{
                          ...inputStyles,
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: `${pinError && "#d32f2f"}`,
                          },
                          "& .MuiFormHelperText-root": {
                            color: "#d32f2f",
                          },
                        }}
                        helperText={pinError && pinError}
                      />
                    </Box>
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel htmlFor="email" sx={{ ...inputLabelStyles }}>
                        Email:
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="email"
                        id="email"
                        sx={{ ...inputStyles }}
                        disabled={false}
                      />
                    </Box>
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel htmlFor="phone" sx={{ ...inputLabelStyles }}>
                        Mobile (+91):
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="number"
                        name="phone"
                        id="phone"
                        disabled
                        sx={{
                          ...inputStyles,

                          "& .MuiFormHelperText-root": {
                            color: "#d32f2f",
                          },
                          ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                            {
                              opacity: 0.5,
                            },
                        }}
                      />
                    </Box>
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="time_slot"
                        sx={{ ...inputLabelStyles }}
                      >
                        Preferred Time Slot:
                      </InputLabel>
                      <Field
                        component={Select}
                        type="text"
                        name="time_slot"
                        id="time_slot"
                        sx={{ width: "350px", borderRadius: "10px" }}
                        disabled={false}
                      >
                        <MenuItem value="9AM - 3PM">9AM - 3PM</MenuItem>
                        <MenuItem value="3PM - 9PM">3PM - 9PM</MenuItem>
                      </Field>
                    </Box>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Stack>
        {showMeasurementsInfo && (
          <Stack
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            sx={{ width: "100%", maxWidth: { xs: "705px", xl: "760px" } }}
          >
            <Box
              component="span"
              sx={{
                p: 2,
                border: "1px dashed grey",
                marginBottom: "20px",
                maxWidth: "400px",
              }}
            >
              <Typography variant="h5" gutterBottom>
                Measurements:
              </Typography>
              <Typography
                fontSize="16px"
                fontWeight={theme.fontWeight.regular}
                textAlign="left"
              >
                Once the order is booked, Our executive will be at your doorstep
                for measurements within in 24 to 48 hrs.
              </Typography>
            </Box>
          </Stack>
        )}
      </Stack>
      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddressAndDelivery;
