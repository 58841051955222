import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Slide,
  Typography,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import theme from "../../theme";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppointmentTimer = ({
  openDialog,
  setOpenDialog,
  setOpenBookingDialog,
}) => (
  <Dialog TransitionComponent={Transition} open={openDialog} onClose={() => {}}>
    <DialogContent>
      <Grid
        sx={{
          width: { md: "370px" },
          height: { md: "300px" },
          flexDirection: { xs: "column", md: "row" },
          rowGap: "20px",
          columnGapGap: "20px",
        }}
        container
      >
        <Grid item md={12} key={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{
                fontWeight: theme.fontWeight.bold,
                fontSize: { xs: "24px", md: "32px" },
              }}
            >
              BOOK FREE APPOINTMENT
            </Typography>
            <IconButton
              onClick={() => {
                setOpenDialog(false);
              }}
              sx={{
                borderRadius: "56px",
                display: "flex",
                alignItems: "center",
                fontSize: "13px",
                fontWeight: theme.fontWeight.semiBold,
                textAlign: "center",
                textTransform: "none",
                marginBottom: "60px",
                padding: "0px",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item key={2}>
          <Typography fontWeight={theme.fontWeight.light} fontSize="14px">
            Confused on which fabric to choose?
          </Typography>
          <Typography fontWeight={theme.fontWeight.light} fontSize="14px">
            Don't worry. Our executive will be at your doorstep with fabrics
            samples for look and feel.
          </Typography>
        </Grid>
        <Grid
          sx={{
            padding: "20px 0",
          }}
          item
          xs={2}
          md={12}
          key={3}
        >
          <Button
            sx={{
              borderRadius: "10px",
              backgroundColor: "black",
              color: "white",
              fontSize: "18px",
              fontWeight: theme.fontWeight.semiBold,
              textAlign: "center",
              height: "49px",
              width: "203px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "black",
              },
            }}
            onClick={(event) => {
              event.preventDefault();
              setOpenDialog(false);
              setOpenBookingDialog(true);
            }}
          >
            BOOK NOW
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>
);

export default AppointmentTimer;
