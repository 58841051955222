import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  typography: {
    fontFamily: 'Monospace'
  },
  breakpoints: {
    values: {
      xs: 0,
      xs350: 350,
      sm: 600,
      sm700: 700,
      sm768: 768,
      md: 900,
      md1000: 1000,
      lg: 1200,
      lg1300: 1200,
      xl: 1200
    }
  },
  padding: {
    pagePaddingXS: 10,
    pagePaddingLG: 20,
    pagePaddingXL: 20
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700
  }
})

export default theme
