import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { mapWithOrderProductIds } from "./helpers";

const initialState = {
  orders: null,
  loading: false,
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrdersLoading(state, actions) {
      state.loading = actions.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.orders = action.payload;
        state.loading = false;
      });
  },
});

export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async ({ mobile, products }, thunkAPI) => {
    setOrdersLoading(true);
    const ordersRef = collection(db, "orders");
    const q = query(ordersRef, where("mobile", "==", mobile));
    const querySnapshot = await getDocs(q);

    let userOrders = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let oOrder = doc.data();
      oOrder.products.map((oProduct) => {
        oProduct.measurmentIndex = "";
        return oProduct;
      })
      userOrders.push(mapWithOrderProductIds(products, oOrder));
    });

    userOrders.sort((a, b) =>
      parseInt(a.order_id) > parseInt(b.order_id) ? -1 : 1
    );

    setOrdersLoading(false);

    return userOrders;
  }
);

export default ordersSlice.reducer;
export const { setOrdersLoading } = ordersSlice.actions;
