/* eslint-disable react-hooks/exhaustive-deps */
// Styles
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  setProductsFilters,
  setProductsByCategory
} from "../../features/products/productsSlice";
import { useAppDispatch } from "../../app/store";

const ScrollToTop = ({ params }) => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if(pathname !== "/shirts" && pathname !== "/pants"){
      dispatch(setProductsByCategory([]))
      dispatch(setProductsFilters({
        collection: [],
        color: [],
        pattern: [],
      }));
    }
    // const canControlScrollRestoration = "scrollRestoration" in window.history
    // if (canControlScrollRestoration) {
    //   window.history.scrollRestoration = "manual"
    // }
    // document.getElementById("root")?.scrollIntoView({ behavior: "smooth" })
    window.scrollTo(0, 0);
  }, [params, pathname]);

  return null;
};

export default ScrollToTop;
