import { useState, lazy, Suspense } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { Loading, Navbar, Subscribe, ScrollToTop, Footer } from "./components";
import { Box } from "@mui/material";
const Home = lazy(() => import("./pages/Home"));
const Auth = lazy(() => import("./pages/Auth"));
const Orders = lazy(() => import("./pages/Orders"));
const Invoice = lazy(() => import("./pages/Invoice"));
const Profile = lazy(() => import("./pages/Profile"));
const TermsCondition = lazy(() => import("./pages/TermsCondition"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const OrderPolicies = lazy(() => import("./pages/OrderPolicies"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const FAQ = lazy(() => import("./pages/FAQ"));
const PaymentStatus = lazy(() => import("./pages/PaymentStatus"));
const ShippingPolicy = lazy(() => import("./pages/ShippingPolicy"));

function App() {
  const [openBookingDialog, setOpenBookingDialog] = useState(false);

  const renderLoader = () => <Loading />;

  return (
    <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
      <Suspense fallback={renderLoader()}>
        <BrowserRouter>
          <ScrollToTop />
          <Subscribe />
          <Navbar
            openBookingDialog={openBookingDialog}
            setOpenBookingDialog={setOpenBookingDialog}
          />
          <Loading />
          <Routes>
            <Route
              path="/"
              element={<Home setOpenBookingDialog={setOpenBookingDialog} />}
            />
            <Route path="/login" element={<Auth />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/view-invoice" element={<Invoice />} />
            <Route path="/payment-status" element={<PaymentStatus />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/terms-condition" element={<TermsCondition />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/policies" element={<OrderPolicies />} />
            <Route path="/shippingpolicy" element={<ShippingPolicy />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </Suspense>
    </Box>
  );
}

export default App;
