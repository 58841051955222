import React from "react";
import { Box, Drawer, List, ListItem, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import theme from "../../theme";

const NavbarMobileMenu = ({
  isDrawerOpened,
  setIsDrawerOpened,
  handleLogin,
  handleLogout,
  handleNavigation,
  handleClickShoppingCart,
  handleClickFavoriteIcon,
}) => {
  const {
    currentUser: { currentUser },
  } = useSelector((state) => state);

  const handleCloseDrawer = () => {
    setIsDrawerOpened(false);
  };

  const renderCategories = () => {
    return (
      <>
        {currentUser ? (
          <>
            <Box>
              <ListItem
                sx={{
                  cursor: "pointer",
                  fontSize: "18px",
                  borderTop: "1px solid lightgray",
                  fontFamily: theme.typography.fontFamily,
                  justifyContent: "center",
                  fontWeight: theme.fontWeight.semiBold,
                }}
                onClick={() => {
                  handleNavigation("profile");
                }}
              >
                PROFILE
              </ListItem>
            </Box>
            {/* <Box>
              <ListItem
                sx={{
                  display: { xs: "flex", sm: "flex", md: "none" },
                  cursor: "pointer",
                  fontSize: "18px",
                  borderTop: "1px solid lightgray",
                  borderBottom: "1px solid lightgray",
                  fontFamily: theme.typography.fontFamily,
                  justifyContent: "center",
                  fontWeight: theme.fontWeight.semiBold,
                }}
                onClick={handleClickShoppingCart}
              >
                <>
                  SHOPPPING CART{" "}
                  {user?.user_products_in_cart.length && currentUser ? (
                    <>({user?.user_products_in_cart.length})</>
                  ) : (
                    <></>
                  )}
                </>
              </ListItem>
            </Box> */}
            {/* <Box>
              <ListItem
                sx={{
                  display: { xs: "flex", sm: "flex", md: "none" },
                  cursor: "pointer",
                  fontSize: "18px",
                  borderTop: "1px solid lightgray",
                  borderBottom: "1px solid lightgray",
                  fontFamily: theme.typography.fontFamily,
                  justifyContent: "center",
                  fontWeight: theme.fontWeight.semiBold,
                }}
                onClick={handleClickFavoriteIcon}
              >
                WISHLIST
              </ListItem>
            </Box> */}
            <Box>
              <ListItem
                sx={{
                  cursor: "pointer",
                  fontSize: "18px",
                  borderTop: "1px solid lightgray",
                  borderBottom: "1px solid lightgray",
                  fontFamily: theme.typography.fontFamily,
                  justifyContent: "center",
                  fontWeight: theme.fontWeight.semiBold,
                }}
                onClick={() => {
                  handleNavigation("orders");
                }}
              >
                ORDERS
              </ListItem>
            </Box>
            <Box>
              <ListItem
                sx={{
                  cursor: "pointer",
                  fontSize: "18px",
                  borderTop: "1px solid lightgray",
                  borderBottom: "1px solid lightgray",
                  fontFamily: theme.typography.fontFamily,
                  justifyContent: "center",
                  fontWeight: theme.fontWeight.semiBold,
                }}
                onClick={handleLogout}
              >
                LOGOUT
              </ListItem>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <ListItem
                sx={{
                  cursor: "pointer",
                  fontSize: "18px",
                  borderTop: "1px solid lightgray",
                  fontFamily: theme.typography.fontFamily,
                  justifyContent: "center",
                  fontWeight: theme.fontWeight.semiBold,
                }}
                onClick={handleLogin}
              >
                LOGIN
              </ListItem>
            </Box>
            {/* <Box>
              <ListItem
                sx={{
                  cursor: "pointer",
                  fontSize: "18px",
                  borderTop: "1px solid lightgray",
                  fontFamily: theme.typography.fontFamily,
                  justifyContent: "center",
                  fontWeight: theme.fontWeight.semiBold,
                }}
                onClick={handleClickShoppingCart}
              >
                SHOPPING CART
              </ListItem>
            </Box>
            <Box>
              <ListItem
                sx={{
                  cursor: "pointer",
                  fontSize: "18px",
                  borderTop: "1px solid lightgray",
                  borderBottom: "1px solid lightgray",
                  fontFamily: theme.typography.fontFamily,
                  justifyContent: "center",
                  fontWeight: theme.fontWeight.semiBold,
                }}
                onClick={handleClickFavoriteIcon}
              >
                WISHLIST
              </ListItem>
            </Box> */}
          </>
        )}
      </>
    );
  };

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpened}
      onClose={handleCloseDrawer}
      sx={{
        "& .MuiDrawer-paperAnchorRight": {
          width: "100%",
          maxWidth: { xs: "250px", md: "300px" },
        },
        padding: "20px",
      }}
      data-testid="mobileMenu"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="10px"
      >
        <Close
          sx={{ cursor: "pointer", marginLeft: "auto" }}
          onClick={handleCloseDrawer}
        />
      </Stack>

      <List>{renderCategories()}</List>
    </Drawer>
  );
};

export default NavbarMobileMenu;
