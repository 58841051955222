// Styles
import { Stack, Box, Typography, Grid } from "@mui/material";
import theme from "../../theme";
import {
  Payment as PaymentIcon,
  Loyalty as LoyaltyIcon,
  ContentCut as ContentCutIcon,
  Percent as PercentIcon,
} from "@mui/icons-material";
import listData from "./listData.json";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import StoreIcon from "@mui/icons-material/Store";
const WhyChooseUs = () => {
  // {
  //   "id": "payments",
  //   "title": "Easy Payments",
  //   "description": "Shop with ease and convenience with our secure and user-friendly payment options, making it quick and easy for you to complete your purchase and get your hands on your favorite items."
  // },

  const renderIcon = (iconName) => {
    switch (iconName) {
      case "shipping":
        return <BookOnlineIcon sx={{ width: "70px", height: "70px" }} />;
      case "payments":
        return <PaymentIcon sx={{ width: "70px", height: "70px" }} />;
      case "discount":
        return <PercentIcon sx={{ width: "70px", height: "70px" }} />;
      case "money":
        return <ContentCutIcon sx={{ width: "70px", height: "70px" }} />;
      case "quality":
        return <LoyaltyIcon sx={{ width: "70px", height: "70px" }} />;
      case "store":
        return <StoreIcon sx={{ width: "70px", height: "70px" }} />;
      default:
        return <></>;
    }
  };

  const renderList = (item) => {
    return (
      <Stack
        key={item.id}
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        {renderIcon(item.id)}
        <Typography
          component="h2"
          sx={{
            marginTop: { xs: "10px", sm: "20px" },
            marginBottom: "20px",
            fontSize: "16px",
            fontWeight: theme.fontWeight.semiBold,
          }}
        >
          {item.title}
        </Typography>
        <Typography
          component="h3"
          sx={{
            color: "#262626",
            fontWeight: theme.fontWeight.regular,
            fontSize: "13px",
            width: { xs: "100%", md: "300px" },
          }}
        >
          {item.description}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack
      component="section"
      sx={{
        alignItems: "center",
        rowGap: "10px",
        marginTop: "50px",
        marginBottom: "30px",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Typography
          component="h1"
          align="center"
          sx={{
            fontSize: { xs: "24px", lg: "32px" },
            fontWeight: theme.fontWeight.light,
          }}
        >
          Why should you choose us?
        </Typography>
      </Box>
      {/* <Stack
        direction="row"
        sx={{
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          columnGap: { xs: "20px", sm: "40px", lg: "50px", xl: "93px" },
          rowGap: "50px",
          maxWidth: { xs: "700px", lg: "initial" },
        }}
      >
        {listData.map((item) => renderList(item))}
      </Stack> */}
      <Grid
        sx={{
          padding: "20px",
        }}
        container
        rowSpacing={3}
        columnSpacing={6}
      >
        {listData.map((item) => (
          <Grid item xs={6} key={item.id}>
            {renderList(item)}
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default WhyChooseUs;
