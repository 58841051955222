export const mapWithOrderProductIds = (products, Orders) => {
  Orders.products = Orders.products.map((oProduct) => {
    const productFound = products.find(
      (item) => item.id === oProduct.product_id
    );
    oProduct = {
      ...oProduct,
      ...productFound,
    };
    return oProduct;
  });
  return Orders;
};
