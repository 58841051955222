/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Tooltip,
  TextField,
  Popover,
} from "@mui/material";
import { auth } from "../../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Person as PersonIcon,
  AccessAlarm as AccessAlarmIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import theme from "../../theme";
import logo from "../../images/logo.webp";
import NavbarMobileMenu from "../NavbarMenuMobile";
import AppointmentTimer from "../AppointmentTimer";
import BookAppointment from "../BookAppointment";
import { fetchAppointment } from "../../features/appointment/appointmentSlice";
import { useAppDispatch } from "../../app/store";

const inputStyles = {
  "& .MuiInputBase-root": {
    borderRadius: "10px",
    width: { xs: "280px", md: "400px" },
  },

  "& .MuiFormHelperText-root": {
    margin: "5px 0px 0px 5px",
    width: "300px",
  },

  "& .MuiInputBase-input": {
    fontSize: { sm: "18px" },
    fontWeight: { sm: theme.fontWeight.regular },
  },
};

let timer = null;
function Navbar({ openBookingDialog, setOpenBookingDialog }) {
  const {
    user: { user },
    currentUser: { currentUser },
    appointment: { appointment },
  } = useSelector((state) => state);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const setTimerAppointment = () => {
    if (!timer) {
      timer = setTimeout(() => {
        setOpenDialog(true);
      }, 15 * 1000);

      return () => clearTimeout(timer);
    }
  };
  useEffect(() => {
    setTimerAppointment();
  }, []);

  useEffect(() => {
    if (openBookingDialog && timer) {
      clearTimeout(timer);
    }
  }, [openBookingDialog]);

  useEffect(() => {
    if (user) {
      dispatch(
        fetchAppointment({
          mobile: user.mobile,
        })
      );
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      if (appointment?.length > 0) {
        if (timer) {
          clearTimeout(timer);
        }
      } else {
        setTimerAppointment();
      }
    }
  }, [user, appointment]);

  const handleCloseSearch = () => {
    setAnchorEl(null);
  };

  const openSearch = Boolean(anchorEl);
  const id = openSearch ? "simple-popover" : undefined;

  const handleNavigation = (category) => {
    navigate({
      pathname: `/${category}`,
    });
    handleCloseUserMenu();
  };

  const handleClickFavoriteIcon = () => {
    if (currentUser) {
      navigate({ pathname: "/wishlist" });
    } else {
      handleLogin();
    }
    handleCloseUserMenu();
  };

  const handleLogin = () => {
    handleCloseUserMenu();
    navigate("/login");
    localStorage.setItem("from", pathname);
    clearTimeout(timer);
  };

  const handleClickShoppingCart = () => {
    if (currentUser) {
      navigate({ pathname: "/cart" });
    } else {
      handleLogin();
    }
    handleCloseUserMenu();
  };

  const handleOpenUserMenu = (event) => {
    if (currentUser) {
      setIsDrawerOpened(true);
    } else {
      handleLogin();
    }
  };

  const handleCloseUserMenu = () => {
    setIsDrawerOpened(false);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    auth.signOut();
    navigate("/");
  };

  return (
    <>
      <AppBar
        id="AppBar"
        position="fixed"
        sx={{ boxShadow: "none", backgroundColor: "white" }}
      >
        <Typography
          sx={{
            fontSize: { xs: "12px", md: "18px" },
            background: "lightgray",
            textAlign: "center",
            color: "black",
          }}
        >
          &#8377; All prices are inclusive of fabric and stitching &#8377;
        </Typography>

        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
              <Box
                component="img"
                sx={{
                  cursor: "pointer",
                  width: { xs: "100px", md: "150px" },
                  height: { xs: "60px", md: "80px" },
                  padding: "10px",
                }}
                onClick={() => {
                  handleNavigation("");
                }}
                alt="Welcome to Keimple, where tailor made formal shirts & formal pants meets your unique style. We offer exceptional service, and high-quality fabrics for your satisfaction. Join us in Surat for confident and comfortable fashion."
                src={logo}
              ></Box>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
              <IconButton
                sx={{
                  borderRadius: "10px",
                  alignItems: "center",
                  fontSize: "18px",
                  fontWeight: theme.fontWeight.semiBold,
                  textAlign: "center",
                  textTransform: "none",
                  backgroundColor: "white",
                  marginRight: "5px",
                }}
                onClick={() => {
                  setOpenBookingDialog(true);
                }}
              >
                <AccessAlarmIcon sx={{ marginRight: "5px" }} />
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: theme.fontWeight.semiBold,
                  }}
                  textAlign="center"
                >
                  APPOINTMENT
                </Typography>
              </IconButton>
            </Box>

            <Box sx={{ display: { xs: "block", sm: "none", md: "none" } }}>
              <IconButton
                aria-label="Appointment"
                sx={{
                  width: "34px",
                  height: "34px",
                  marginRight: "10px",
                }}
                onClick={() => {
                  setOpenBookingDialog(true);
                }}
              >
                <AccessAlarmIcon sx={{ marginRight: "5px" }} />
              </IconButton>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip>
                <IconButton
                  aria-label="Account"
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                >
                  <PersonIcon />
                </IconButton>
              </Tooltip>
              <NavbarMobileMenu
                isDrawerOpened={isDrawerOpened}
                setIsDrawerOpened={setIsDrawerOpened}
                handleLogin={handleLogin}
                handleLogout={handleLogout}
                handleNavigation={handleNavigation}
                handleClickShoppingCart={handleClickShoppingCart}
                handleClickFavoriteIcon={handleClickFavoriteIcon}
              />
            </Box>
          </Toolbar>
        </Container>

        <Popover
          id={id}
          open={openSearch}
          anchorEl={anchorEl}
          onClose={handleCloseSearch}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box
            style={{
              display: "flex",
              margin: "auto",
              padding: "10px",
            }}
          >
            <TextField
              placeholder="Search product code, name, etc."
              sx={{ ...inputStyles }}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  if (search) {
                    navigate(`/search?id=${search}`);
                    handleCloseSearch();
                  }
                }
              }}
            ></TextField>
            <IconButton
              sx={{
                marginLeft: "10px",
              }}
              onClick={() => {
                if (search) {
                  navigate(`/search?id=${search}`);
                  handleCloseSearch();
                }
              }}
            >
              <SearchIcon
                sx={{
                  fontSize: "2rem",
                }}
              />
            </IconButton>
          </Box>
        </Popover>
        <AppointmentTimer
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          setOpenBookingDialog={setOpenBookingDialog}
        />

        <BookAppointment
          openBookingDialog={openBookingDialog}
          setOpenBookingDialog={setOpenBookingDialog}
        />
      </AppBar>
    </>
  );
}
export default Navbar;
