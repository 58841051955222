/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import theme from "../../theme";
import { useSelector } from "react-redux";
import { ScrollToTop } from "../../components";
import DiscountIcon from "@mui/icons-material/Discount";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { setOrderLoading } from "../../features/order/orderSlice";
import { useAppDispatch } from "../../app/store";
import { collection, query, where, and, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import suitingClassic from "../../constants/suitingClassic.json";
import suitingBold from "../../constants/suitingBold.json";

const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "18px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const CartSummary = ({ coupon, selectedAddress, deliveryType }) => {
  const [cartSummaryData, setCartSummaryData] = useState();
  const [dialogTitle, setDialogTitle] = useState("");
  const [message, setMessage] = useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useAppDispatch();
  const {
    user: { user },
    currentUser,
    products: { cartProducts },
  } = useSelector((state) => state);
  const navigate = useNavigate();

  const handleDialogClose = () => {
    setOpenDialog(false);

    //if (dialogTitle === "Success") {
    navigate({ pathname: "/orders" });
    //}
  };

  const checkIfPendingOrder = async () => {
    const ordersRef = collection(db, "orders");
    const q = query(
      ordersRef,
      and(
        where("mobile", "==", user.mobile),
        where("payment_status", "==", "Pending")
      )
    );

    const querySnapshot = await getDocs(q);

    const aPendingOrders = [];
    querySnapshot.forEach((doc) => {
      aPendingOrders.push(doc.data());
    });

    return aPendingOrders.length > 0;
  };

  const onConfirmOrder = async () => {
    try {
      if (currentUser.currentUser) {
        if (user) {
          dispatch(setOrderLoading(true));

          const bHasPendingOrders = await checkIfPendingOrder();
          if (bHasPendingOrders) {
            setDialogTitle("Error");
            setMessage(
              "One order is open and in process. Next order can be processed once the open orders is closed."
            );
            setOpenDialog(true);
            dispatch(setOrderLoading(false));

            return;
          }
          let aProducts = [];

          cartProducts.products.forEach((oProduct) => {
            aProducts.push({
              product_id: oProduct.id,
              price: oProduct.price.toString(),
              category: oProduct.category,
              product_detail: oProduct.product_detail,
              pair_number: 0,
              quantity: oProduct.quantity,
            });
          });

          let oDeliveryDate = new Date();
          oDeliveryDate.setDate(oDeliveryDate.getDate() + 7);
          const oOrderData = {
            account_name: user?.account_name ? user?.account_name : "",
            mobile: user.mobile,
            email: user.email,
            user_address:
              deliveryType === "get-delivered" ? selectedAddress : null,
            delivery_type: deliveryType,
            created_on: new Date().toString(),
            total_amount: cartProducts.totalCost.toString(),
            amount_paid: "0",
            discount: cartProducts.discount,
            products: aProducts,
            payment_status: "Pending",
            payment_mode: "",
            coupon_code: coupon,
            order_version: 3,
            order_type: "online",
            payment_history: [],
            delivery_date: oDeliveryDate.toString(),
            order_status: [
              {
                status: "Order Placed",
                created_on: new Date().toString(),
              },
              {
                status: "Order Shipped",
                created_on: "",
              },
              {
                status: "Order Delivered",
                created_on: "",
              },
              {
                status: "Order Cancelled",
                created_on: "",
              },
            ],
          };

          const functions = getFunctions();
          const onCreateOrderWithPG = httpsCallable(
            functions,
            "onCreateOrderWithPG"
          );

          onCreateOrderWithPG(oOrderData)
            .then((result) => {
              const paymentURL = result.data.payment_url;
              window.location.replace(paymentURL);
            })
            .catch((oError) => {
              setDialogTitle("Error");
              setMessage("Error while placing order. Please try again later.");
              setOpenDialog(true);
              dispatch(setOrderLoading(false));
            });
        }
      }
    } catch (error) {
      dispatch(setOrderLoading(false));
      setDialogTitle("Error");
      setMessage("Error while placing order. Please try again later.");
      setOpenDialog(true);
    }
  };

  useEffect(() => {
    if (currentUser.currentUser) {
      let addressData = Object.assign({}, user.address);
      if (addressData) {
        setCartSummaryData({
          addressData,
        });
      }
    }
  }, []);

  const getShirtDetails = (product) => {
    return (
      <>
        <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
          {product.product_detail.sleeve}
        </Typography>
        <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
          {product.product_detail.collor}
        </Typography>
        <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
          {product.product_detail.pocket}
        </Typography>
      </>
    );
  };

  const getPantDetails = (product) => {
    let oFoundSuiting = null;

    if (product.collection === "Classic") {
      suitingClassic.some((oSuiting) => {
        if (oSuiting.category === product.product_detail.suiting_type) {
          oFoundSuiting = oSuiting;
          return true;
        }
        return false;
      });
    } else if (product.collection === "Bold") {
      suitingBold.some((oSuiting) => {
        if (oSuiting.category === product.product_detail.suiting_type) {
          oFoundSuiting = oSuiting;
          return true;
        }
        return false;
      });
    }
    return (
      <>
        {oFoundSuiting && (
          <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
            {oFoundSuiting.label}
          </Typography>
        )}
      </>
    );
  };

  return (
    <>
      <ScrollToTop id="Addrress" />
      {cartSummaryData && cartProducts.products && (
        <>
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            <Table>
              <Thead>
                <Tr>
                  <Th
                    style={{
                      fontSize: "16px",
                      fontWeight: theme.fontWeight.semiBold,
                      minWidth: "600px",
                      width: "600px",
                    }}
                  >
                    PRODUCT
                  </Th>
                  <Th
                    style={{
                      fontSize: "16px",
                      fontWeight: theme.fontWeight.semiBold,
                      minWidth: "100px",
                    }}
                  >
                    PRODUCT DETAILS
                  </Th>
                  <Th
                    style={{
                      fontSize: "16px",
                      fontWeight: theme.fontWeight.semiBold,
                      minWidth: "100px",
                    }}
                  >
                    PRICE
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {cartProducts.products?.map((product, iIndex) => {
                  return (
                    <Tr key={iIndex}>
                      <Td>
                        <Box
                          alignItems="center"
                          sx={{
                            gap: "10px",
                            display: {
                              xs: "block",
                              sm: "flex",
                              md: "flex",
                              lg: "flex",
                            },
                          }}
                        >
                          {product?.card_image_url?.includes("http") ? (
                            <>
                              <Box
                                sx={{
                                  display: { xs: "none", md: "block" },
                                  width: "40%",
                                  height: "40%",
                                }}
                              >
                                <LazyLoadImage
                                  threshold={10}
                                  visibleByDefault={true}
                                  alt={product.product_name}
                                  src={product?.card_image_url}
                                  width="90%"
                                  height="40%"
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: { xs: "block", md: "none" },
                                  width: "100%",
                                  height: "80%",
                                }}
                              >
                                <LazyLoadImage
                                  threshold={10}
                                  visibleByDefault={true}
                                  alt={product.product_name}
                                  src={product?.card_image_url}
                                  width="100%"
                                  height="80%"
                                />
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box
                                sx={{
                                  display: { xs: "none", md: "block" },
                                  width: "40%",
                                  height: "40%",
                                  border: "1px solid lightgray",
                                }}
                              >
                                <Typography
                                  sx={{
                                    marginTop: "60px",
                                    textAlign: "center",
                                    width: "200px",
                                    height: "100px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Image not available
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: { xs: "block", md: "none" },
                                  width: "100%",
                                  height: "80%",
                                  border: "1px solid lightgray",
                                }}
                              >
                                <Typography
                                  sx={{
                                    marginTop: "60px",
                                    textAlign: "center",
                                    height: "80px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Image not available
                                </Typography>
                              </Box>
                            </>
                          )}
                          <Typography
                            sx={{
                              width: { xs: "150px", md: "300px" },
                            }}
                            fontSize="18px"
                            fontWeight={theme.fontWeight.regular}
                          >
                            {product.product_name} ({product.product_code})
                          </Typography>
                        </Box>
                      </Td>
                      <Td>
                        {" "}
                        {product.category === "shirt" && (
                          <>{getShirtDetails(product)}</>
                        )}
                        {product.category === "pant" && (
                          <>{getPantDetails(product)}</>
                        )}
                      </Td>
                      <Td style={{ minWidth: "100px" }}>
                        <Typography
                          fontSize="18px"
                          fontWeight={theme.fontWeight.regular}
                          color="#7b7b7b"
                        >
                          &#8377;
                          {Number(parseFloat(product.price)).toLocaleString(
                            "en",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </Typography>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{
              marginTop: "20px",
              justifyContent: { xs: "center", md: "space-between" },
            }}
          >
            <Box
              sx={{
                minHeight: "100px",
              }}
            >
              <Stack
                direction="row"
                sx={{
                  justifyContent: { xs: "center", md: "flex-start" },
                  marginTop: { xs: "20px", md: "10px" },
                }}
              ></Stack>
              <Stack alignItems="end">
                {cartProducts.discount > 0 && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    columnGap="10px"
                    width="244px"
                    height="49px"
                    sx={{
                      borderRadius: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <DiscountIcon />
                    <Typography
                      fontWeight={theme.fontWeight.light}
                      fontSize="16px"
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {cartProducts.discount}%
                      </span>{" "}
                      discount applied!
                    </Typography>
                  </Stack>
                )}
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  columnGap="33px"
                  width="300px"
                  height="49px"
                  sx={{
                    backgroundColor: "#F1F1F1",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <Typography
                    fontWeight={theme.fontWeight.regular}
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                    }}
                  >
                    TOTAL AMOUNT :
                  </Typography>
                  <Typography
                    fontWeight={theme.fontWeight.semiBold}
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                    }}
                  >
                    &#8377;
                    {Number(parseFloat(cartProducts.totalCost)).toLocaleString(
                      "en",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
            <Stack
              sx={{
                alignItems: { xs: "center", md: "flex-end" },
              }}
            >
              <Button
                onClick={onConfirmOrder}
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "black",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: theme.fontWeight.semiBold,
                  textAlign: "center",
                  height: "49px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "black",
                  },
                }}
              >
                PAY ONLINE & PLACE ORDER
              </Button>
            </Stack>
          </Stack>
        </>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              sx={{
                color: "black",
              }}
              fontWeight={theme.fontWeight.regular}
              fontSize="18px"
            >
              {message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              ...stepperButtonStyles,
            }}
            onClick={handleDialogClose}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CartSummary;
