/* eslint-disable array-callback-return */
/* eslint-disable no-empty-pattern */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
import { mapWithFavoriteProductIds } from "./helpers";
import cloneDeep from "lodash/cloneDeep";

const initialState = {
  products: null,
  productsByIds: null,
  productsByCategory: null,
  shirts: null,
  topShirts: null,
  pants: null,
  topPants: null,
  buttons: null,
  cartProducts: {
    products: null,
    totalCost: 0,
    actualCost: 0,
    discount: 0,
    outOfStock: false,
  },
  favorite_products: [],
  filtered_products: [],
  filter: false,
  loading: false,
  topShirtsloading: false,
  topPantsloading: false,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setCartProductsAndTotalCost(state, actions) {
      const mappedProducts = [];
      if (state.products?.length) {
        state.products.forEach((product) => {
          const matchedProductInCart = actions.payload.userProducts.find(
            (item) => item.product_id === product.product_code
          );
          if (!!matchedProductInCart) {
            let oNewProduct = {
              ...product,
              product_detail: matchedProductInCart.product_detail,
              category: matchedProductInCart.category,
              quantity: matchedProductInCart.quantity,
            };

            if (matchedProductInCart.category === "pant") {
              oNewProduct.price = matchedProductInCart.price;
            }
            mappedProducts.push(oNewProduct);
          }
        });

        state.cartProducts.products = mappedProducts;
      }

      if (mappedProducts.length) {
        let total = mappedProducts.reduce(
          (previousValue, currentValue) =>
            previousValue + parseFloat(currentValue.price),
          0
        );
        state.cartProducts.actualCost = +total.toFixed(2);
        state.cartProducts.discount = 0;

        if (actions.payload.offerDetails) {
          let oData = actions.payload.offerDetails;
          if (total > oData.min_order) {
            state.cartProducts.discount = oData.discount;
            const fnPercentage = function (sTotal) {
              return (sTotal * state.cartProducts.discount) / 100;
            };
            total = total - fnPercentage(total);
          }
        }

        state.cartProducts.totalCost = total;

        mappedProducts.some((oProduct) => {
          if (parseInt(oProduct.stock_quantity) === 0) {
            state.cartProducts.outOfStock = true;
            return true;
          }
          return false;
        });
      } else {
        state.cartProducts.discount = 0;
        state.cartProducts.totalCost = 0;
      }
    },
    setFavoriteProducts(state, action) {
      if (state.products && action.payload && action.payload.length > 0) {
        const copyProducts = cloneDeep(state.products);
        const matchedProducts = copyProducts.filter((product) => {
          const isFavorite = action.payload.some((id) => product.id === id);
          if (isFavorite) {
            product.isFavorite = isFavorite;
          }
          return isFavorite;
        });
        state.favorite_products = matchedProducts;
      } else {
        state.favorite_products = [];
      }
    },
    setProductsFilters(state, action) {
      state.filter = false;

      let priceProducts = [];
      let colorProducts = [];
      let patternProducts = [];
      let matchedProducts = [];
      if (state.productsByCategory) {
        const copyProducts = cloneDeep(state.productsByCategory);

        if (action?.payload?.collection?.length > 0) {
          priceProducts = copyProducts.filter((product) => {
            let bFound = false;
            action.payload.collection.some((sCollection) => {
              state.filter = true;
              if (product.collection === sCollection.toString()) {
                bFound = true;
                return true;
              }
              return false;
            });

            if (bFound) {
              return product;
            }
          });
        }

        if (action?.payload?.color?.length > 0) {
          const copyProducts = cloneDeep(state.productsByCategory);

          colorProducts = copyProducts.filter((product) => {
            let bFound = false;
            action.payload.color.some((sPrice) => {
              state.filter = true;
              if (product.color.toLowerCase().includes(sPrice.toString())) {
                bFound = true;
                return true;
              }
              return false;
            });

            if (bFound) {
              return product;
            }
          });
        }

        if (action?.payload?.pattern?.length > 0) {
          patternProducts = copyProducts.filter((product) => {
            let bFound = false;
            action.payload.pattern.some((sPrice) => {
              state.filter = true;
              if (product.pattern.toLowerCase() === sPrice.toString()) {
                bFound = true;
                return true;
              }
              return false;
            });

            if (bFound) {
              return product;
            }
          });
        }
      }

      if (priceProducts.length > 0) {
        let priceColorProducts = [];
        if (colorProducts.length > 0) {
          priceColorProducts = priceProducts.filter((oPriceProduct) =>
            colorProducts.some(
              (oPatternProduct) =>
                oPatternProduct.product_code === oPriceProduct.product_code
            )
          );

          if (patternProducts.length > 0) {
            matchedProducts = priceColorProducts.filter((oPriceProduct) =>
              patternProducts.some(
                (oPatternProduct) =>
                  oPatternProduct.product_code === oPriceProduct.product_code
              )
            );
          } else {
            matchedProducts = priceColorProducts;
          }
        } else if (patternProducts.length > 0) {
          matchedProducts = priceProducts.filter((oPriceProduct) =>
            patternProducts.some(
              (oPatternProduct) =>
                oPatternProduct.product_code === oPriceProduct.product_code
            )
          );
        } else {
          matchedProducts = priceProducts;
        }
      } else if (colorProducts.length > 0) {
        if (patternProducts.length > 0) {
          matchedProducts = colorProducts.filter((oPriceProduct) =>
            patternProducts.some(
              (oPatternProduct) =>
                oPatternProduct.product_code === oPriceProduct.product_code
            )
          );
        } else {
          matchedProducts = colorProducts;
        }
      } else if (patternProducts.length > 0) {
        matchedProducts = patternProducts;
      }

      state.filtered_products = matchedProducts;
    },
    setFilters(state, actions) {
      state.filter = actions.payload;
    },
    setProductsByCategory(state, actions) {
      state.productsByCategory = actions.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(fetchProductsByCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductsByCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.productsByCategory = action.payload;
      })
      .addCase(fetchShirts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchShirts.fulfilled, (state, action) => {
        state.loading = false;
        state.shirts = action.payload;
      })
      .addCase(fetchTopShirts.pending, (state) => {
        state.topShirtsloading = true;
      })
      .addCase(fetchTopShirts.fulfilled, (state, action) => {
        state.topShirtsloading = false;
        state.topShirts = action.payload;
      })
      .addCase(fetchPants.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPants.fulfilled, (state, action) => {
        state.loading = false;
        state.pants = action.payload;
      })
      .addCase(fetchTopPants.pending, (state) => {
        state.topPantsloading = true;
      })
      .addCase(fetchTopPants.fulfilled, (state, action) => {
        state.topPantsloading = false;
        state.topPants = action.payload;
      })
      .addCase(fetchButtons.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchButtons.fulfilled, (state, action) => {
        state.loading = false;
        state.buttons = action.payload;
      });
  },
});

export const fetchAllProducts = createAsyncThunk(
  "products/fetchAllProducts",
  async ({ search }) => {
    let q = query(collection(db, "shirts"));
    const querySnapshot = await getDocs(q);
    let data = [];

    if (!data || !data.length) {
      querySnapshot.docs.map((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        docData.category = "shirt";
        if (docData.collection !== "Linen") {
          data.push(docData);
        }
      });

      let q1 = query(collection(db, "pants"));
      const querySnapshot1 = await getDocs(q1);

      querySnapshot1.docs.map((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        docData.category = "pant";
        if (docData.collection !== "Linen") {
          data.push(docData);
        }
      });
    }

    let aFilteredDate = data;
    if (search) {
      let sSearch = search.toLowerCase();
      aFilteredDate = data.filter((oData) => {
        if (
          oData.product_code.toLowerCase().includes(sSearch) ||
          oData.product_name.toLowerCase().includes(sSearch) ||
          oData.product_description.toLowerCase().includes(sSearch) ||
          oData.color.toLowerCase().includes(sSearch) ||
          oData.pattern.toLowerCase().includes(sSearch)
        ) {
          if (
            parseInt(oData.stock_quantity) > 0 &&
            parseInt(oData.price) < 2500
          ) {
            return oData;
          }
        }
      });
    }
    return aFilteredDate;
  }
);

export const fetchProductsByCategories = createAsyncThunk(
  "products/fetchProductsByCategories",
  async ({ category, favoriteProductIds }, thunkAPI) => {
    let data = [];
    // if products didn't cached before get products from server and cache
    if (!data || data.length === 0) {
      data = [];
      let q = query(collection(db, category));
      let querySnapshot = await getDocs(q);

      querySnapshot.docs.forEach((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        if (
          parseInt(docData.stock_quantity) > 0 &&
          parseInt(docData.price) < 2500
        ) {
          data.push(docData);
        }
      });
    }

    let filteredProducts = data;
    if (favoriteProductIds) {
      filteredProducts = mapWithFavoriteProductIds(data, favoriteProductIds);
    }
    return filteredProducts;
  }
);

export const fetchShirts = createAsyncThunk(
  "products/fetchShirts",
  async ({ favoriteProductIds }) => {
    let data = [];

    // if products didn't cached before get products from server and cache
    if (!data || data.length === 0) {
      data = [];
      let q = query(collection(db, "shirts"));
      let querySnapshot = await getDocs(q);

      querySnapshot.docs.forEach((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        if (
          parseInt(docData.stock_quantity) > 0 &&
          parseInt(docData.price) < 2500
        ) {
          data.push(docData);
        }
      });
    }
    let filteredProducts = data;
    if (favoriteProductIds) {
      filteredProducts = mapWithFavoriteProductIds(data, favoriteProductIds);
    }

    filteredProducts.map((oProduct) => {
      if (oProduct.isFavorite) {
        oProduct.grouping = "Favorites";
      } else {
        oProduct.grouping = "Shirts";
      }
      return oProduct;
    });

    filteredProducts.sort(function (a, b) {
      return a.grouping.localeCompare(b.grouping);
    });

    return filteredProducts;
  }
);

export const fetchTopShirts = createAsyncThunk(
  "products/fetchTopShirts",
  async ({ favoriteProductIds, topShirt }) => {
    let data = [];

    // if products didn't cached before get products from server and cache
    if (!data || data.length === 0) {
      data = [];
      let q = query(collection(db, "shirts"));
      let querySnapshot = await getDocs(q);

      querySnapshot.docs.forEach((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        docData.category = "shirt";
        if (
          parseInt(docData.stock_quantity) > 0 &&
          parseInt(docData.price) < 2500
        ) {
          data.push(docData);
        }
      });
    }
    let filteredProducts = data;
    if (favoriteProductIds) {
      filteredProducts = mapWithFavoriteProductIds(data, favoriteProductIds);
    }

    if (topShirt && filteredProducts.length > 4) {
      filteredProducts = filteredProducts.sort((a, b) =>
        parseInt(b.stock_quantity) > parseInt(a.stock_quantity) ? -1 : 1
      );
      filteredProducts = filteredProducts.slice(0, 4);
    }

    return filteredProducts;
  }
);

export const fetchPants = createAsyncThunk(
  "products/fetchPants",
  async ({ favoriteProductIds }) => {
    let data = [];

    // if products didn't cached before get products from server and cache
    if (!data || data.length === 0) {
      data = [];
      let q = query(collection(db, "pants"));
      let querySnapshot = await getDocs(q);

      querySnapshot.docs.forEach((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        if (
          parseInt(docData.stock_quantity) > 0 &&
          parseInt(docData.price) < 2500
        ) {
          data.push(docData);
        }
      });
    }
    let filteredProducts = data;
    if (favoriteProductIds) {
      filteredProducts = mapWithFavoriteProductIds(data, favoriteProductIds);
    }

    filteredProducts.map((oProduct) => {
      if (oProduct.isFavorite) {
        oProduct.grouping = "Favorites";
      } else {
        oProduct.grouping = "Pants";
      }
      return oProduct;
    });

    filteredProducts.sort(function (a, b) {
      return a.grouping.localeCompare(b.grouping);
    });

    return filteredProducts;
  }
);

export const fetchTopPants = createAsyncThunk(
  "products/fetchTopPants",
  async ({ favoriteProductIds, topPant }) => {
    let data = [];

    // if products didn't cached before get products from server and cache
    if (!data || data.length === 0) {
      data = [];
      let q = query(collection(db, "pants"));
      let querySnapshot = await getDocs(q);

      querySnapshot.docs.forEach((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        docData.category = "pant";
        if (
          parseInt(docData.stock_quantity) > 0 &&
          parseInt(docData.price) < 2500
        ) {
          data.push(docData);
        }
      });
    }
    let filteredProducts = data;
    if (favoriteProductIds) {
      filteredProducts = mapWithFavoriteProductIds(data, favoriteProductIds);
    }

    if (topPant && filteredProducts.length > 4) {
      filteredProducts = filteredProducts.sort((a, b) =>
        parseInt(b.stock_quantity) > parseInt(a.stock_quantity) ? -1 : 1
      );
      filteredProducts = filteredProducts.slice(0, 4);
    }

    return filteredProducts;
  }
);

export const fetchButtons = createAsyncThunk(
  "products/fetchButtons",
  async () => {
    let data = [];

    // if products didn't cached before get products from server and cache
    if (!data || data.length === 0) {
      data = [];
      let q = query(collection(db, "buttons"));
      let querySnapshot = await getDocs(q);

      querySnapshot.docs.forEach((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        data.push(docData);
      });
    }
    return data;
  }
);

export default productsSlice.reducer;
export const {
  setCartProductsAndTotalCost,
  setFavoriteProducts,
  setProductsFilters,
  setFilters,
  setProductsByCategory,
} = productsSlice.actions;
