export const filterByIds = (data, productIds) => {
  const filteredData = data.filter(item => productIds.includes(item.id))
  return filteredData
}

export const filterBySubCategories = (secondary, tertiaryIds, data) => {
  let filteredData = []

  filteredData = data.filter(item => {
    return (
      secondary.includes(item.secondary_category) &&
      tertiaryIds.includes(item.tertiary_category)
    )
  })

  return filteredData
}

export const mapWithFavoriteProductIds = (products, favoriteProductIds) => {
  if (favoriteProductIds) {
    return products.map(product => {
      if (favoriteProductIds.includes(product.id)) {
        product.isFavorite = true
      }
      return product
    })
  } else {
    return products
  }
}

export const getCachedProductsByPrimaryCategory = primary => {
  const products = JSON.parse(localStorage.getItem(primary))
  return products
}