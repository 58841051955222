import carousels from "../../constants/mainCarouselData.json";
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";

import desktop_1 from "../../images/desktop/desktop_1.webp";
import desktop_2 from "../../images/desktop/desktop_2.webp";
import desktop_3 from "../../images/desktop/desktop_3.webp";
import desktop_4 from "../../images/desktop/desktop_4.webp";

const getDesktopImage = (sName) => {
  if (sName === "desktop_1") {
    return desktop_1;
  } else if (sName === "desktop_2") {
    return desktop_2;
  } else if (sName === "desktop_3") {
    return desktop_3;
  } else if (sName === "desktop_4") {
    return desktop_4;
  }
};

const CarouselPage = ({ setOpenBookingDialog }) => {
  return (
    <Box
      sx={{
        marginTop: { xs: "80px", md: "100px" },
      }}
    >
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <LazyLoadImage
              width="100%"
              height="100%"
              fetchpriority="high"
              className="d-block w-100"
              src={getDesktopImage(carousels[0].src.desktop)}
              alt={carousels[0].alt}
            ></LazyLoadImage>
          </div>
          <div className="carousel-item">
            <LazyLoadImage
              width="100%"
              height="100%"
              fetchpriority="high"
              onClick={() => {
                const oElement = document.getElementById("idCollections");
                window.scrollTo(0, oElement.offsetTop - 100);
              }}
              className="d-block w-100"
              src={getDesktopImage(carousels[1].src.desktop)}
              alt={carousels[1].alt}
            ></LazyLoadImage>
          </div>
          <div className="carousel-item">
            <LazyLoadImage
              width="100%"
              height="100%"
              fetchpriority="high"
              onClick={() => {
                const oElement = document.getElementById("idCollections");
                window.scrollTo(0, oElement.offsetTop - 100);
              }}
              className="d-block w-100"
              src={getDesktopImage(carousels[2].src.desktop)}
              alt={carousels[2].alt}
            ></LazyLoadImage>
          </div>
          <div className="carousel-item">
            <LazyLoadImage
              width="100%"
              height="100%"
              fetchpriority="high"
              onClick={() => {
                setOpenBookingDialog(true)
              }}
              className="d-block w-100"
              src={getDesktopImage(carousels[3].src.desktop)}
              alt={carousels[3].alt}
            ></LazyLoadImage>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </Box>
  );
};

export default CarouselPage;
