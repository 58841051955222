import { Button, Box, Grid, Radio, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import theme from "../../theme";
import storeAddress from "../../constants/storeAddress.json";

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: "1.5px solid black",
  borderRadius: "10px",
}));

const DeliveryOption = ({ setActiveStep, deliveryType, setDeliveryType }) => {
  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item>
          <Item>
            <Stack
              onClick={() => {
                setDeliveryType("in-store");
              }}
              sx={{
                width: { md: "300px" },
                flexDirection: "row",
                cursor: "pointer",
                opacity:
                  deliveryType === ""
                    ? "1"
                    : deliveryType === "in-store"
                    ? "1"
                    : "0.6",
              }}
            >
              <Radio
                checked={deliveryType === "in-store"}
                onChange={() => {
                  setDeliveryType("in-store");
                }}
              />
              <Box>
                <Typography
                  sx={{
                    fontWeight: theme.fontWeight.semiBold,
                    fontSize: "18px",
                  }}
                >
                  IN STORE PICKUP
                </Typography>
                <>
                  <Typography>
                    {`${storeAddress.first_name} ${storeAddress.last_name}`}
                  </Typography>
                  <Typography>
                    {`${storeAddress.address}, ${storeAddress.city}, ${storeAddress.pin_code}`}{" "}
                  </Typography>
                  <Typography>
                    <span style={{ fontWeight: "bold" }}>Mobile: </span>
                    {`${storeAddress.mobile.substring(3)}`}
                  </Typography>
                </>
              </Box>
            </Stack>
          </Item>
        </Grid>
        <Grid item>
          <Item>
            <Stack
              onClick={() => {
                setDeliveryType("get-delivered");
              }}
              sx={{
                width: { md: "300px" },
                flexDirection: "row",
                cursor: "pointer",
                opacity:
                  deliveryType === ""
                    ? "1"
                    : deliveryType === "get-delivered"
                    ? "1"
                    : "0.6",
              }}
            >
              <Radio
                checked={deliveryType === "get-delivered"}
                onChange={() => {
                  setDeliveryType("get-delivered");
                }}
              />
              <Box>
                <Typography
                  sx={{
                    fontWeight: theme.fontWeight.semiBold,
                    fontSize: "18px",
                  }}
                >
                  GET DELIVERED
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                  }}
                >
                  Get products delivered at your doorstep
                </Typography>
              </Box>
            </Stack>
          </Item>
        </Grid>
      </Grid>
      {deliveryType !== "" && (
        <Stack
          rowGap="10px"
          width="100%"
          direction="column"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Button
            sx={{
              borderRadius: "10px",
              backgroundColor: "black",
              color: "white",
              fontSize: "16px",
              fontWeight: theme.fontWeight.semiBold,
              textAlign: "center",
              height: "49px",
              width: "203px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "black",
              },
              marginTop: "20px",
            }}
            onClick={(event) => {
              if (deliveryType === "get-delivered") {
                setActiveStep(2);
              } else {
                setActiveStep(3);
              }
            }}
          >
            NEXT
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default DeliveryOption;
