import { Stack, Typography, Box, Grid } from "@mui/material";
import collectionData from "./collectionData.json";
import theme from "../../theme";

const Collections = () => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Typography
          align="center"
          sx={{
            fontSize: { xs: "18px", lg: "32px" },
            fontWeight: theme.fontWeight.semiBold,
          }}
        >
          OUR COLLECTIONS
        </Typography>
      </Box>
      <Stack
        id="idCollections"
        sx={{
          marginTop: "10px",
          maxWidth: { xs: "350px", md: "600px" },
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Grid container rowSpacing={3}>
          {collectionData.map((data) => (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              item
              xs={6}
              key={data.id}
            >
              <Stack
                key={data.id}
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  width: "90%",
                  height: { xs: "80px", md: "120px" },
                  background: "black",
                }}
              >
                <Typography
                  align="center"
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    fontSize: { xs: "16px", md: "24px" },
                    zIndex: 3,
                  }}
                >
                  {data.text}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default Collections;
