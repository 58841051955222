import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { db } from "../../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import {
  Stack,
  Typography,
  Button,
  Box,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import theme from "../../theme";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import { Link } from "react-router-dom";
import Timer from "../Timer";
import logo from "../../images/logo.webp";
import OtpInput from "react-otp-input";

const inputStyles = {
  "& .MuiInputBase-root": {
    borderRadius: "10px",
    width: "100%",
    minWidth: { sm: "336px" },
  },

  "& .MuiFormHelperText-root": {
    margin: "5px 0px 0px 5px",
  },

  "& .MuiInputBase-input": {
    fontSize: { sm: "18px" },
    fontWeight: { sm: theme.fontWeight.regular },
  },
};

const initialValues = { mobile: "" };

const Login = () => {
  const navigate = useNavigate();
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [mobile, setMobile] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [reSendOtp, SetReSendOtp] = useState(false);
  const [mobileResult, setMobileResult] = useState("");
  const [otp, setOtp] = useState("");

  const checkUserExistInDB = async (userId) => {
    const docRef = doc(db, "users", userId);
    const user = await getDoc(docRef);
    return user.exists();
  };

  const loginWithMobile = async (values) => {
    setLoading(true);

    const auth = getAuth();
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: () => {
            console.log("Recaptca verified");
          },
        },
        auth
      );
    }

    signInWithPhoneNumber(auth, "+91" + values.mobile, window.recaptchaVerifier)
      .then((result) => {
        setMobile("+91" + values.mobile);
        setLoading(false);
        setOtpSent(true);
        setMobileResult(result);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const validateOtp = () => {
    setLoading(true);

    mobileResult
      .confirm(otp)
      .then(async ({ user }) => {
        const isUserExistInDB = await checkUserExistInDB(user.phoneNumber);
        if (!isUserExistInDB) {
          const userData = {
            mobile: user.phoneNumber,
            email: user.email || "",
            fullName: user.displayName || "",
            favoriteProductIds: [],
            user_products_in_cart: [],
            created_on: new Date().toString(),
            measurements: [],
            last_modified: new Date().toString(),
            addresses: [],
          };
          await setDoc(doc(db, "users", user.phoneNumber), userData);
        }
        setLoading(false);

        const from = localStorage.getItem("from");
        if (from) {
          navigate(from);
        } else {
          navigate("/");
        }
        window.recaptchaVerifier = null;
      })
      .catch((error) => {
        let typedError = error;
        switch (typedError.code) {
          case "auth/invalid-verification-code":
            setOtpError("Invalid Code");
            break;
          default:
            break;
        }
        setLoading(false);
      });
  };

  const returnErrorMessage = (fieldName, value) => {
    let errorMessage = "";
    if (fieldName === "mobile") {
      if (!value) {
        errorMessage = "Mobile number is required";
      } else if (value.toString().length !== 10) {
        errorMessage = "Enter a valid mobile number";
      }
    } else if (fieldName === "otp") {
      if (!value) {
        errorMessage = "Code is required";
      }
    }
    return errorMessage;
  };

  const validateField = (fieldName, value, callback) => {
    let isValid = true;
    const error = returnErrorMessage(fieldName, value);
    if (error) {
      callback(error);
      isValid = false;
    }

    return isValid;
  };

  return (
    <Stack
      alignItems="center"
      sx={{
        minHeight: "500px",
        marginTop: { xs: "90px", md: "120px" },
        padding: "16px",
        "& form": {
          display: "flex",
          flexDirection: "column",
          rowGap: "15px",
          width: "100%",
          maxWidth: "448px",
        },
        "& .MuiOutlinedInput-root": {
          borderColor: "#D8D8D8",
        },
      }}
    >
      {!otpSent && (
        <>
          <Box
            sx={{
              cursor: "pointer",
            }}
          >
            <img
              style={{
                width: "200px",
                height: "100px",
                padding: "10px",
              }}
              alt=""
              src={logo}
            ></img>
          </Box>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: theme.fontWeight.semiBold,
              marginBottom: "24px",
            }}
          >
            LOGIN / SIGN UP
          </Typography>
          <Formik initialValues={initialValues} onSubmit={() => {}}>
            {({ values, validateForm, setTouched }) => {
              return (
                <Form
                  id="loginForm"
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    validateForm(values).then((error) => {
                      // validate not formik fields
                      const isPhoneValid = validateField(
                        "mobile",
                        values.mobile,
                        (error) => {
                          setPhoneError(error);
                        }
                      );

                      if (isPhoneValid) {
                        loginWithMobile(values);
                      }
                    });
                  }}
                >
                  <Field
                    component={TextField}
                    type="number"
                    name="mobile"
                    id="mobile"
                    label="MOBILE e.g.98XXXXXXXX"
                    disabled={loading}
                    sx={{
                      ...inputStyles,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: `${phoneError && "#d32f2f"}`,
                      },
                      "& .MuiFormHelperText-root": {
                        color: "#d32f2f",
                      },
                    }}
                    helperText={phoneError && phoneError}
                  />
                </Form>
              );
            }}
          </Formik>
          <Box
            sx={{
              paddingLeft: { xs: "0px", sm: "20px", md: "20px", lg: "20px" },
              display: "flex",
            }}
          >
            <Checkbox
              checked={isTermsChecked}
              onChange={(event) => {
                setIsTermsChecked(event.target.checked);
              }}
              name="gilad"
            />

            <Typography
              sx={{
                marginTop: "10px",
                fontSize: "12px",
                maxWidth: { xs: "400px", sm: "448px" },
                fontWeight: theme.fontWeight.regular,
              }}
            >
              By creating an account or logging in, you agree with Keimple's{" "}
              <Link
                to="/terms-condition"
                target="_blank"
                sx={{
                  color: "initial",
                  textDecoration: "none",
                }}
              >
                Terms and Conditions
              </Link>{" "}
              and{" "}
              <Link
                to="/privacy-policy"
                target="_blank"
                sx={{
                  color: "initial",
                  textDecoration: "none",
                }}
              >
                Privacy Policy
              </Link>
              .
            </Typography>
          </Box>
          <Button
            disabled={loading || !isTermsChecked}
            id="sign-in-button"
            variant="contained"
            form="loginForm"
            type="submit"
            sx={{
              borderRadius: "10px",
              backgroundColor: "black",
              display: "flex",
              alignItems: "center",
              fontSize: "18px",
              fontWeight: theme.fontWeight.semiBold,
              marginTop: "20px",
              maxWidth: { xs: "400px", sm: "448px" },
              textAlign: "center",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "black",
              },
              height: "56px",
              width: "100%",
            }}
          >
            SEND OTP
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "white",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        </>
      )}
      {otpSent && (
        <>
          <Box
            sx={{
              cursor: "pointer",
            }}
          >
            <img
              style={{
                width: "200px",
                height: "100px",
                padding: "10px",
              }}
              alt=""
              src={logo}
            ></img>
          </Box>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: theme.fontWeight.semiBold,
              marginBottom: "24px",
            }}
          >
            Enter Verification Code
          </Typography>
          <Stack
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Stack
              sx={{
                width: { xs: "100%", md: "30%" },
                justifyContent: "space-around",
                display: "flex",
              }}
            >
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputType="tel"
                renderSeparator={
                  <span
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  ></span>
                }
                renderInput={(props) => (
                  <input
                    {...props}
                    style={{
                      width: "20%",
                      height: "50px",
                      textAlign: "center",
                      fontSize: "24px",
                    }}
                  />
                )}
              />
              {otpError && (
                <Typography
                  sx={{
                    marginBottom: "10px",
                    color: "#d32f2f",
                    fontWeight: "400",
                    lineHeight: "1.66",
                    textAlign: "left",
                    marginTop: "3px",
                    marginRight: "14px",
                  }}
                >
                  {otpError}
                </Typography>
              )}
            </Stack>
            {!reSendOtp && (
              <Timer
                onClick={() => {
                  SetReSendOtp(true);
                  loginWithMobile({
                    mobile: mobile.substring(3),
                  });
                }}
              />
            )}
          </Stack>
          <Button
            disabled={loading}
            variant="contained"
            onClick={() => {
              // validate not formik fields
              const isPhoneValid = validateField("otp", otp, (error) => {
                setOtpError(error);
              });

              if (isPhoneValid) {
                validateOtp();
              }
            }}
            sx={{
              borderRadius: "10px",
              backgroundColor: "black",
              display: "flex",
              alignItems: "center",
              fontSize: "18px",
              fontWeight: theme.fontWeight.semiBold,
              marginTop: "20px",
              maxWidth: { xs: "400px", sm: "448px" },
              textAlign: "center",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "black",
              },
              height: "56px",
              width: "100%",
            }}
          >
            LOGIN
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "white",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        </>
      )}
    </Stack>
  );
};

export default Login;
