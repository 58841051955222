import { useNavigate, useLocation } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import theme from "../../theme";

import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "./index.css";
import FavoriteButton from "../FavoriteButton";

const MobileProductCard = ({
  product,
  setShowTrialRoom,
  setSelectedProduct,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleClickCard = (product) => {
    localStorage.setItem("product_code", product.product_code);
    let sPathname = "";
    if (product.category) {
      sPathname = product.category;
    } else {
      sPathname = pathname.substring(1, pathname.length - 1);
    }
    navigate({
      pathname: `/${sPathname}`,
      search: `?id=${product.id}`,
    });
  };

  const getPriceLabel = (product) => {
    let sName = "";
    if (
      product.category === "pant" ||
      pathname.substring(1, pathname.length - 1) === "pant"
    ) {
      sName = "Starting from";
    }

    return <>{sName} </>;
  };

  return (
    <>
      <Stack
        onClick={() => {
          handleClickCard(product);
        }}
        id={product.product_code}
        sx={{
          justifyContent: "space-between",
          cursor: "pointer",
          boxShadow: "none",
          transition: "box-shadow 0.2s linear",
          borderLeft: "1px solid #e6e6e6",
          borderTop: "1px solid #e6e6e6",
          borderBottom: "1px solid #e6e6e6",
          "&:hover": {
            boxShadow: "1px 5px 7px 0px rgba(175,171,171,.7)",
          },
        }}
      >
        <Stack>
          <Box
            sx={{
              display: { xs: "block", md: "none" },
              position: "relative",
            }}
          >
            {product?.card_image_url?.includes("http") ? (
              <LazyLoadImage
                className="product-image"
                alt={product.product_name}
                height="150px"
                src={product.card_image_url} // use normal <img> attributes as props
                width="100%"
              />
            ) : (
              <Box
                sx={{
                  height: "150px",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "10px",
                    position: "absolute",
                    left: "18%",
                    top: "38%",
                  }}
                >
                  Image not available
                </Typography>
              </Box>
            )}
            <FavoriteButton
              productId={product.id}
              isFavorite={product.isFavorite}
            />
          </Box>
          <Stack sx={{ paddingLeft: "5px", paddingRight: "5px" }}>
            <Typography
              noWrap
              sx={{
                width: "180px",
                fontSize: "12px",
                textOverflow: "ellipsis",
              }}
            >
              {product.product_name}
            </Typography>
          </Stack>
        </Stack>
        <Box
          sx={{
            paddingLeft: "5px",
            paddingRight: "5px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              alignSelf: "center",
              fontSize: "12px",
              fontWeight: theme.fontWeight.regular,
              paddingTop: "5px",
              paddingBottom: "5px",
              color: "#7b7b7b",
            }}
          >
            {getPriceLabel(product)} &#8377;
            {Number(parseFloat(product.price)).toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

export default trackWindowScroll(MobileProductCard);
