/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { doc, updateDoc } from "firebase/firestore";
import {
  Box,
  InputLabel,
  Stack,
  Button,
  Snackbar,
  Alert as MuiAlert,
  MenuItem,
} from "@mui/material";
import theme from "../../theme";
import { Field, Form, Formik, setNestedObjectValues } from "formik";
import { TextField, Select } from "formik-mui";
import { useAppDispatch } from "../../app/store";
import { setUserLoading } from "../../features/user/userSlice";
import { db } from "../../firebase";
import cloneDeep from "lodash/cloneDeep";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const inputContainerStyles = {
  height: "90px",
};

const inputLabelStyles = {
  color: "#000000",
  cursor: "pointer",
  fontSize: "16px",
  fontWeight: theme.fontWeight.semiBold,
  marginBottom: "5px",
};

const inputStyles = {
  "& .MuiInputBase-root": {
    borderRadius: "0px",
    width: "350px",
  },

  "& .MuiFormHelperText-root": {
    margin: "5px 0px 0px 5px",
    width: "300px",
  },

  "& .MuiInputBase-input": {
    fontSize: { sm: "18px" },
    fontWeight: { sm: theme.fontWeight.regular },
  },
};

const validationSchema = yup.object({
  first_name: yup
    .string()
    .trim()
    .matches(/^[\p{L}\p{M}-]+$/u, "First name cannot contain a number")
    .min(2, "First name should be of minimum 2 characters length")
    .required("First name is required"),
  last_name: yup
    .string()
    .trim()
    .matches(/^[\p{L}\p{M}-]+$/u, "Last name cannot contain a number")
    .min(2, "Last name should be of minimum 2 characters length")
    .required("Last name is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  time_slot: yup.string().required("Time slot is required"),
});

const AddressForm = ({ setEditMode, selectedAddress, addressIndex }) => {
  const {
    user: { user },
    currentUser,
  } = useSelector((state) => state);
  const [pinError, setPinError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [initialFormValues, setInitialFormValues] = useState({
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    pin_code: "",
    mobile: "",
    time_slot: "",
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const dispatch = useAppDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    setInitialFormValues(selectedAddress);
  }, [selectedAddress]);

  const returnErrorMessage = (fieldName, value) => {
    let errorMessage = "";
    if (fieldName === "pin_code") {
      if (!value) {
        errorMessage = "Pin code is required";
      } else if (value.toString().length !== 6) {
        errorMessage = "Invalid pin code";
      } else if (!(394101 <= parseInt(value) && parseInt(value) <= 396510)) {
        errorMessage = "Delivery not available";
      }
    } else if (fieldName === "mobile") {
      if (!value) {
        errorMessage = "Mobile is required";
      } else if (value.toString().length !== 10) {
        errorMessage = "Invalid mobile";
      }
    }
    return errorMessage;
  };

  const validateField = (fieldName, value, callback) => {
    let isValid = true;
    const error = returnErrorMessage(fieldName, value);
    if (error) {
      callback(error);
      isValid = false;
    }

    return isValid;
  };

  const handleSubmitForm = async (values) => {
    if (currentUser.currentUser) {
      dispatch(setUserLoading(true));
      const docRef = doc(db, "users", currentUser.currentUser.mobile);

      try {
        let aAddress = [];

        if (user?.addresses) {
          aAddress = cloneDeep(user.addresses);

          if (addressIndex === -1) {
            aAddress.push({
              first_name: values.first_name,
              last_name: values.last_name,
              address: values.address,
              city: values.city,
              pin_code: values.pin_code.toString(),
              mobile: "+91" + values.mobile.toString(),
              time_slot: values.time_slot,
            });
          } else {
            aAddress[addressIndex] = {
              first_name: values.first_name,
              last_name: values.last_name,
              address: values.address,
              city: values.city,
              pin_code: values.pin_code.toString(),
              mobile: "+91" + values.mobile.toString(),
              time_slot: values.time_slot,
            };
          }
        }
        await updateDoc(docRef, {
          last_modified: new Date().toString(),
          addresses: aAddress,
        });
        setEditMode(false);
        dispatch(setUserLoading(false));
      } catch (error) {
        setAlertMessage("Error while updaing profile. Please try again later.");
        setAlertSeverity("error");
        setOpenAlert(true);
        dispatch(setUserLoading(false));
      }
    }
  };

  return (
    <>
      <Stack
        sx={{
          paddingLeft: {
            xs: theme.padding?.pagePaddingXS + "px",
            lg: theme.padding?.pagePaddingLG + "px",
            xl: theme.padding?.pagePaddingXL + "px",
          },
          paddingRight: {
            xs: theme.padding?.pagePaddingXS + "px",
            lg: theme.padding?.pagePaddingLG + "px",
            xl: theme.padding?.pagePaddingXL + "px",
          },
        }}
        rowGap="30px"
      >
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="flex-start"
          rowGap="60px"
          sx={{
            justifyContent: { xs: "center", lg1300: "center" },
            columnGap: { xs: "10px", lg: "25px", xl: "54px" },
          }}
        >
          <Formik
            initialValues={initialFormValues}
            onSubmit={() => {}}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ values, validateForm, setTouched }) => {
              return (
                <Form
                  id="AddressForm"
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    validateForm(values).then(async (error) => {
                      const isPinValid = validateField(
                        "pin_code",
                        values.pin_code,
                        (error) => {
                          setPinError(error);
                        }
                      );

                      if (isPinValid) {
                        setPinError("");
                      }

                      const isMobileValid = validateField(
                        "mobile",
                        values.mobile,
                        (error) => {
                          setMobileError(error);
                        }
                      );

                      if (isMobileValid) {
                        setMobileError("");
                      }

                      if (
                        Object.keys(error).length > 0 ||
                        !isPinValid ||
                        !isMobileValid
                      ) {
                        // show all errors
                        setTouched(setNestedObjectValues(error, true));
                      } else {
                        handleSubmitForm(values);
                      }
                    });
                  }}
                >
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="start"
                    sx={{ gap: { xs: "10px 25px", xl: "15px 87px" } }}
                  >
                    {/* FIRST NAME */}
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="first_name"
                        sx={{ ...inputLabelStyles }}
                      >
                        FIRST NAME:
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="first_name"
                        id="first_name"
                        sx={{ ...inputStyles }}
                        disabled={false}
                      />
                    </Box>

                    {/* LAST NAME */}
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="last_name"
                        sx={{ ...inputLabelStyles }}
                      >
                        LAST NAME:
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="last_name"
                        id="last_name"
                        sx={{ ...inputStyles }}
                        disabled={false}
                      />
                    </Box>

                    {/* ADDRESS */}
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="address"
                        sx={{ ...inputLabelStyles }}
                      >
                        ADDRESS:
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="address"
                        id="address"
                        sx={{ ...inputStyles }}
                        disabled={false}
                      />
                    </Box>

                    {/* CITY */}
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel htmlFor="city" sx={{ ...inputLabelStyles }}>
                        CITY:
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="city"
                        id="city"
                        sx={{ ...inputStyles }}
                        disabled={false}
                      />
                    </Box>

                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="pin_code"
                        sx={{ ...inputLabelStyles }}
                      >
                        PIN CODE:
                      </InputLabel>

                      <Field
                        component={TextField}
                        type="number"
                        name="pin_code"
                        id="pin_code"
                        disabled={false}
                        sx={{
                          ...inputStyles,
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: `${pinError && "#d32f2f"}`,
                          },
                          "& .MuiFormHelperText-root": {
                            color: "#d32f2f",
                          },
                        }}
                        helperText={pinError && pinError}
                      />
                    </Box>

                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel htmlFor="mobile" sx={{ ...inputLabelStyles }}>
                        MOBILE (+91):
                      </InputLabel>
                      <Field
                        component={TextField}
                        type="number"
                        name="mobile"
                        id="mobile"
                        sx={{
                          ...inputStyles,
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: `${mobileError && "#d32f2f"}`,
                          },
                          "& .MuiFormHelperText-root": {
                            color: "#d32f2f",
                          },
                        }}
                        helperText={mobileError && mobileError}
                      />
                    </Box>
                    <Box sx={{ ...inputContainerStyles }}>
                      <InputLabel
                        htmlFor="time_slot"
                        sx={{ ...inputLabelStyles }}
                      >
                        PREFERRED TIME SLOT:
                      </InputLabel>
                      <Field
                        component={Select}
                        type="text"
                        name="time_slot"
                        id="time_slot"
                        sx={{ width: "350px", borderRadius: "10px" }}
                        disabled={false}
                      >
                        <MenuItem value="9AM - 3PM">9AM - 3PM</MenuItem>
                        <MenuItem value="3PM - 9PM">3PM - 9PM</MenuItem>
                      </Field>
                    </Box>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Stack>

        <Stack
          direction="row"
          width="max-content"
          maxWidth="100%"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          rowGap="20px"
          sx={{ columnGap: { xs: "10px", lg: "26px" } }}
        >
          <Button
            type="submit"
            form="AddressForm"
            sx={{
              borderRadius: "10px",
              backgroundColor: "black",
              color: "white",
              fontSize: "18px",
              fontWeight: theme.fontWeight.semiBold,
              textAlign: "center",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "black",
              },
            }}
          >
            SAVE
          </Button>
          <Button
            onClick={() => {
              setEditMode(false);
            }}
            sx={{
              borderRadius: "10px",
              border: "2px solid black",
              backgroundColor: "white",
              color: "black",
              fontSize: "18px",
              fontWeight: theme.fontWeight.semiBold,
              textAlign: "center",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            CANCEL
          </Button>
        </Stack>
      </Stack>
      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddressForm;
