import { Link as RouterLink } from "react-router-dom";

// Styles
import {
  Facebook,
  Instagram,
  // LinkedIn,
  // Twitter,
  // YouTube,
} from "@mui/icons-material";
import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import theme from "../../theme";
import "./style.css";
// Data
import footerItems from "../../constants/footer.json";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import storeAddress from "../../constants/storeAddress.json";

const Footer = () => {
  const { currentUser } = useSelector((state) => state);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const renderFooterItems = () => {
    return footerItems.map((item) => (
      <Grid
        item
        key={item.title}
        xs={6}
        md={2}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "16px", md: "18px" },
            fontWeight: theme.fontWeight.bold,
            marginBottom: { xs: "10px", md: "28px" },
            width: { xs: "180px", md: "250px" },
          }}
        >
          {item.title}
        </Typography>
        <Stack sx={{ rowGap: "10px", width: { xs: "180px", md: "250px" } }}>
          {item.children.map((child) => (
            <Typography
              key={child.name}
              onClick={() => {
                if (!child.link.includes("orders")) {
                  if (child.link.includes("policies")) {
                    navigate({
                      pathname: child.link,
                      search: `?policy=${child.policy}`,
                    });
                  } else {
                    navigate(child.link);
                  }
                } else if (currentUser.currentUser) {
                  navigate(child.link);
                } else {
                  navigate("/login");
                  localStorage.setItem("from", pathname);
                }
              }}
              sx={{
                cursor: "pointer",
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: theme.fontWeight.regular,

                textDecoration: "none",
                "&:hover": { color: "#FBB03B" },
              }}
            >
              {child.name}
            </Typography>
          ))}
        </Stack>
      </Grid>
    ));
  };
  return (
    <footer className="footer--pin">
      <Grid
        container
        rowGap="40px"
        justifyContent="space-around"
        padding="20px"
      >
        {renderFooterItems()}

        <Grid item xs={12} md={2}>
          {" "}
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "18px" },
              fontWeight: theme.fontWeight.bold,
              marginBottom: { xs: "10px", md: "28px" },
            }}
          >
            STORE ADDRESS
          </Typography>
          <Stack sx={{ rowGap: "10px", width: { xs: "180px", md: "250px" } }}>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: theme.fontWeight.regular,
                textDecoration: "none",
              }}
            >
              {`${storeAddress.first_name} ${storeAddress.last_name}`}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: theme.fontWeight.regular,
                textDecoration: "none",
              }}
            >{`${storeAddress.address}, ${storeAddress.city}, ${storeAddress.pin_code}`}</Typography>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: theme.fontWeight.regular,
                textDecoration: "none",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Mobile: </span>{" "}
              {`${storeAddress.mobile.substring(3)}`}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.fontWeight.bold,
            marginBottom: { xs: "10px", md: "10px" },
            width: "180px",
            textAlign: "center",
          }}
        >
          CONNECT WITH US
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            aria-label="Link for keimple instagram account"
            to="https://www.instagram.com/keimple.in/"
            target="_blank"
            sx={{ color: "white" }}
            component={RouterLink}
          >
            <Instagram />
          </Link>
          <Link
            aria-label="Link for keimple facebook account"
            to="https://www.facebook.com/profile.php?id=61550663800144"
            target="_blank"
            sx={{ color: "white" }}
            component={RouterLink}
          >
            <Facebook />
          </Link>
        </Box>
      </Stack>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "30px",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: theme.fontWeight.regular,
            marginTop: "15px",
            width: "max-content",
            "& a": {
              fontWeight: theme.fontWeight.semiBold,
            },
          }}
        >
          Keimple Pvt. Ltd. &copy; {new Date().getFullYear()}. All rights
          reserved.
        </Typography>
      </Box>
    </footer>
  );
};

export default Footer;
