// Styles
import { FavoriteBorderOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { addProductIdIntoUserFavoriteProducts } from "../../helpers/addProductIdIntoUserFavoriteProducts";
import { useSelector } from "react-redux";
import { removeProductIdFromUserFavoriteProducts } from "../../helpers/removeProductIdFromUserFavoriteProducts";
import { useNavigate, useLocation } from "react-router-dom";
import theme from "../../theme";

const FavoriteButton = ({
  position = "absolute",
  sx,
  productId,
  isFavorite,
  showButton = false,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.currentUser);
  const handleClickFavoriteButton = (event) => {
    event.stopPropagation();
    if (currentUser && !isFavorite) {
      addProductIdIntoUserFavoriteProducts({
        productId,
        userId: currentUser.mobile,
      });
    } else if (currentUser && isFavorite) {
      removeProductIdFromUserFavoriteProducts({
        productId,
        userId: currentUser.mobile,
      });
    } else if (!currentUser) {
      navigate("/login");
      localStorage.setItem("from", pathname);
    }
  };
  return (
    <>
      {showButton ? (
        <>
          <Button
            sx={{
              backgroundColor: `${isFavorite ? "black" : "white"}`,
              border: "2px solid",
              borderRadius: "10px",
              color: `${isFavorite ? "white" : "black"}`,
              display: "flex",
              alignItems: "center",
              fontSize: "18px",
              fontWeight: theme.fontWeight.semiBold,
              textAlign: "center",
              textTransform: "none",
              "&:hover": {
                backgroundColor: `${isFavorite ? "black" : "white"}`,
              },
              height: "56px",
            }}
            onClick={handleClickFavoriteButton}
          >
            <FavoriteBorderOutlined />
            &nbsp;&nbsp;
            <Typography fontSize="16px" fontWeight={theme.fontWeight.semiBold}>
              {isFavorite ? "WISHLISTED" : "WISHLIST"}
            </Typography>
          </Button>
        </>
      ) : (
        <Box
          onClick={handleClickFavoriteButton}
          sx={{
            backgroundColor: `${isFavorite ? "black" : "white"}`,
            color: `${isFavorite ? "white" : "black"}`,
            border: "1px solid #9e9e9e",
            borderRadius: "50%",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: { xs: "30px", md: "40px" },
            width: { xs: "30px", md: "40px" },
            "&:hover svg": {
              fill: `${isFavorite ? "white" : "black"}`,
              transition: "fill 0.2s linear",
            },
            position: position,
            right: { xs: "10px", md: "23px" },
            top: { xs: "10px", md: "19px" },
            ...sx,
          }}
        >
          <FavoriteBorderOutlined
            sx={{
              width: { xs: "20px", md: "30px" },
              height: { xs: "20px", md: "30px" },
            }}
          />
        </Box>
      )}
    </>
  );
};

export default FavoriteButton;
