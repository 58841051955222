/* eslint-disable no-empty-pattern */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { doc, getDocFromServer } from "firebase/firestore";
import { db } from "../../firebase";

const initialState = {
  product: null,
  loading: false,
};

const productSlice = createSlice({
  name: "productSlice",
  initialState,
  reducers: {
    setProduct(state, actions) {
      state.product = actions.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.product = action.payload;
        state.loading = false;
      });
  },
});

export const fetchProduct = createAsyncThunk(
  "product/fetchProduct",
  async ({ category, productId, favoriteProductIds }, thunkAPI) => {
    const docRef = doc(db, `${category}s`, productId);
    const docData = await getDocFromServer(docRef);
    if (docData.exists()) {
      let fetchedProduct = docData.data();
      fetchedProduct.id = docData.id;

      if (favoriteProductIds) {
        const isFavorite = favoriteProductIds.some((id) => id === productId);
        fetchedProduct.isFavorite = isFavorite;
      }
      return fetchedProduct;
    } else {
      return null;
    }
  }
);

export default productSlice.reducer;
export const { setProduct } = productSlice.actions;
