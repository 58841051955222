/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import theme from "../../theme";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  arrayRemove,
  doc,
  updateDoc,
  collection,
  query,
  where,
  and,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useAppDispatch } from "../../app/store";
import {
  fetchAllProducts,
  setCartProductsAndTotalCost,
} from "../../features/products/productsSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { LazyLoadImage } from "react-lazy-load-image-component";
import no_products from "../../images/no_products.png";
import DiscountIcon from "@mui/icons-material/Discount";
import { Close as CloseIcon } from "@mui/icons-material";
import { setOrderLoading } from "../../features/order/orderSlice";
import { useNavigate } from "react-router-dom";
import suitingClassic from "../../constants/suitingClassic.json";
import suitingBold from "../../constants/suitingBold.json";

const stepperButtonStyles = {
  borderRadius: "10px",
  backgroundColor: "black",
  color: "white",
  fontSize: "16px",
  fontWeight: theme.fontWeight.semiBold,
  textAlign: "center",
  height: "49px",
  width: "203px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
};

const CartProductsRenderer = ({
  setShowTrialRoom = null,
  setSelectedProduct = null,
  setActiveStep,
  coupon,
  setCoupon,
}) => {
  const {
    user: { user },
    currentUser,
    products: { products, cartProducts },
  } = useSelector((state) => state);
  const discount = useSelector((state) => state.products.cartProducts.discount);
  const totalCost = useSelector(
    (state) => state.products.cartProducts.totalCost
  );
  const outOfStock = useSelector(
    (state) => state.products.cartProducts.outOfStock
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [couponError, setCouponError] = useState("");

  useEffect(() => {
    if (user && products) {
      dispatch(
        setCartProductsAndTotalCost({
          userProducts: user.user_products_in_cart,
        })
      );
    }
  }, [user, products]);

  useEffect(() => {
    dispatch(fetchAllProducts({}));
  }, [user]);

  const handleClickRemoveProductButton = async (product) => {
    if (currentUser.currentUser) {
      const docRef = doc(db, "users", currentUser.currentUser.mobile);

      let oNewProduct = {};
      user.user_products_in_cart.forEach((oProduct) => {
        if (oProduct.product_id === product.product_id) {
          oNewProduct = oProduct;
        }
      });
      await updateDoc(docRef, {
        user_products_in_cart: arrayRemove(oNewProduct),
        last_modified: new Date().toString(),
      });
    }
  };

  const applyCoupon = async (sCoupon) => {
    if (sCoupon) {
      dispatch(setOrderLoading(true));

      const couponsRef = collection(db, "coupons");
      const q = query(couponsRef, and(where("code", "==", sCoupon)));

      const querySnapshot = await getDocs(q);

      const aCoupons = [];
      querySnapshot.forEach((doc) => {
        aCoupons.push(doc.data());
      });

      if (aCoupons.length > 0) {
        const couponsRef = collection(db, "orders");
        const q = query(
          couponsRef,
          and(
            where("mobile", "==", user.mobile),
            where("coupon_code", "==", sCoupon)
          )
        );

        const querySnapshot = await getDocs(q);

        const aOrders = [];
        querySnapshot.forEach((doc) => {
          aOrders.push(doc.data());
        });

        if (aOrders.length === 0) {
          if (aCoupons[0].count > 0) {
            if (cartProducts.actualCost > aCoupons[0].min_order) {
              const today = new Date();
              today.setHours(0, 0, 0, 0);

              const couponDate = new Date(aCoupons[0].expiry_date);
              couponDate.setHours(0, 0, 0, 0);

              if (today <= couponDate) {
                dispatch(
                  setCartProductsAndTotalCost({
                    userProducts: user.user_products_in_cart,
                    offerDetails: aCoupons[0],
                  })
                );
              } else {
                setCouponError("Coupon expired!");
              }
            } else {
              setCouponError(`Min. amount ₹${aCoupons[0].min_order} required`);
            }
          } else {
            setCouponError("Coupon expired!");
          }
          dispatch(setOrderLoading(false));
        } else {
          setCouponError("Coupon already used!");
          dispatch(setOrderLoading(false));
        }
      } else {
        setCouponError("Invalid coupon!");
        dispatch(setOrderLoading(false));
      }
    } else {
      dispatch(
        setCartProductsAndTotalCost({
          userProducts: user.user_products_in_cart,
        })
      );
    }
  };

  const getShirtDetails = (product) => {
    return (
      <>
        <Typography
          sx={{
            fontSize: { xs: "16px", md: "18px" },
          }}
          fontWeight={theme.fontWeight.regular}
        >
          {product.product_detail.sleeve}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "16px", md: "18px" },
          }}
          fontWeight={theme.fontWeight.regular}
        >
          {product.product_detail.collor}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "16px", md: "18px" },
          }}
          fontWeight={theme.fontWeight.regular}
        >
          {product.product_detail.pocket}
        </Typography>
      </>
    );
  };

  const getPantDetails = (product) => {
    let oFoundSuiting = null;

    if (product.collection === "Classic") {
      suitingClassic.some((oSuiting) => {
        if (oSuiting.category === product.product_detail.suiting_type) {
          oFoundSuiting = oSuiting;
          return true;
        }
        return false;
      });
    } else if (product.collection === "Bold") {
      suitingBold.some((oSuiting) => {
        if (oSuiting.category === product.product_detail.suiting_type) {
          oFoundSuiting = oSuiting;
          return true;
        }
        return false;
      });
    }
    return (
      <>
        {oFoundSuiting && (
          <Typography fontSize="18px" fontWeight={theme.fontWeight.regular}>
            {oFoundSuiting.label}
          </Typography>
        )}
      </>
    );
  };

  return (
    <>
      {cartProducts?.products?.length > 0 ? (
        <Box>
          <Table>
            <Thead>
              <Tr>
                <Th
                  style={{
                    fontSize: "16px",
                    fontWeight: theme.fontWeight.semiBold,
                    minWidth: "600px",
                    width: "600px",
                  }}
                >
                  PRODUCT
                </Th>
                <Th
                  style={{
                    fontSize: "16px",
                    fontWeight: theme.fontWeight.semiBold,
                    minWidth: "100px",
                  }}
                >
                  PRODUCT DETAILS
                </Th>
                <Th
                  style={{
                    fontSize: "16px",
                    fontWeight: theme.fontWeight.semiBold,
                    minWidth: "100px",
                  }}
                >
                  PRICE
                </Th>
                <Th style={{ minWidth: "10px", width: "50px" }}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {cartProducts.products?.map((product, iIndex) => {
                return (
                  <Tr key={iIndex}>
                    <Td>
                      <Box
                        alignItems="center"
                        sx={{
                          gap: "10px",
                          display: {
                            xs: "block",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          cursor:
                            parseInt(product.stock_quantity) === 0
                              ? ""
                              : "pointer",
                        }}
                        onClick={() => {
                          if (parseInt(product.stock_quantity) > 0) {
                            navigate({
                              pathname: `/${product.category}`,
                              search: `?id=${product.id}`,
                            });
                          }
                        }}
                      >
                        {product?.card_image_url?.includes("http") ? (
                          <>
                            <Box
                              sx={{
                                display: { xs: "none", md: "block" },
                                width: "40%",
                                height: "40%",
                              }}
                            >
                              <LazyLoadImage
                                threshold={10}
                                visibleByDefault={true}
                                alt={product.product_name}
                                src={product?.card_image_url}
                                width="90%"
                                height="40%"
                              />
                            </Box>
                            <Box
                              sx={{
                                display: { xs: "block", md: "none" },
                                width: "100%",
                                height: "80%",
                              }}
                            >
                              <LazyLoadImage
                                threshold={10}
                                visibleByDefault={true}
                                alt={product.product_name}
                                src={product?.card_image_url}
                                width="100%"
                                height="80%"
                              />
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{
                                display: { xs: "none", md: "block" },
                                width: "40%",
                                height: "40%",
                                border: "1px solid lightgray",
                              }}
                            >
                              <Typography
                                sx={{
                                  marginTop: "60px",
                                  textAlign: "center",
                                  width: "200px",
                                  height: "100px",
                                  fontSize: "14px",
                                }}
                              >
                                Image not available
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: { xs: "block", md: "none" },
                                width: "100%",
                                height: "80%",
                                border: "1px solid lightgray",
                              }}
                            >
                              <Typography
                                sx={{
                                  marginTop: "60px",
                                  textAlign: "center",
                                  height: "80px",
                                  fontSize: "12px",
                                }}
                              >
                                Image not available
                              </Typography>
                            </Box>
                          </>
                        )}
                        <Typography
                          sx={{
                            width: { xs: "150px", md: "300px" },
                            fontSize: { xs: "16px", md: "18px" },
                          }}
                          fontWeight={theme.fontWeight.regular}
                        >
                          {product.product_name} ({product.product_code}) &nbsp;
                          {parseInt(product.stock_quantity) === 0 && (
                            <>(Out of stock)</>
                          )}
                        </Typography>
                      </Box>
                    </Td>
                    <Td>
                      {product.category === "shirt" && (
                        <>{getShirtDetails(product)}</>
                      )}
                      {product.category === "pant" && (
                        <>{getPantDetails(product)}</>
                      )}
                    </Td>
                    <Td>
                      <Typography
                        fontSize="18px"
                        fontWeight={theme.fontWeight.regular}
                        color="#7b7b7b"
                      >
                        &#8377;
                        {Number(parseFloat(product.price)).toLocaleString(
                          "en",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </Typography>
                    </Td>
                    <Td style={{ minWidth: "100px" }}>
                      <DeleteIcon
                        sx={{ color: "#000000", cursor: "pointer" }}
                        onClick={() => {
                          handleClickRemoveProductButton({
                            product_id: product.id,
                          });
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Stack
            rowGap="20px"
            columnGap="20px"
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            sx={{
              marginTop: "20px",
              justifyContent: {
                xs: "space-between",
                md: "space-between",
                lg: "space-between",
              },
            }}
          >
            <Stack
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              rowGap="10px"
              sx={{
                columnGap: { xs: "10px", lg: "20px" },
                flexDirection: { xs: "row", md: "column" },
              }}
            >
              {user.user_products_in_cart.length > 0 && (
                <>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    columnGap="10px"
                    width="300px"
                    height="49px"
                    sx={{
                      borderRadius: "10px",
                      marginBottom: "15px",
                      marginTop: "10px",
                    }}
                  >
                    {discount === 0 ? (
                      <>
                        <TextField
                          label="Coupon Code"
                          error={couponError !== ""}
                          helperText={couponError}
                          onChange={(event) => {
                            setCoupon(event.target.value);
                            setCouponError("");
                          }}
                        />
                        <Button
                          onClick={() => {
                            applyCoupon(coupon);
                          }}
                          sx={{
                            borderRadius: "10px",
                            backgroundColor: "black",
                            color: "white",
                            fontSize: "16px",
                            fontWeight: theme.fontWeight.semiBold,
                            textAlign: "center",
                            height: "49px",
                            width: "100px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "black",
                            },
                          }}
                        >
                          APPLY
                        </Button>
                      </>
                    ) : (
                      <>
                        <DiscountIcon />
                        <Typography
                          fontWeight={theme.fontWeight.light}
                          fontSize="16px"
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {discount}%
                          </span>{" "}
                          discount applied!
                          <IconButton
                            onClick={() => {
                              setCoupon("");
                              applyCoupon("");
                            }}
                            sx={{
                              marginLeft: "10px",
                              height: "20px",
                              width: "20px",
                              backgroundColor: "black",
                              borderRadius: "56px",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "black",
                              },
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Typography>
                      </>
                    )}
                  </Stack>
                </>
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                columnGap="33px"
                width="300px"
                height="49px"
                sx={{ backgroundColor: "#F1F1F1", borderRadius: "10px" }}
              >
                <Typography
                  fontWeight={theme.fontWeight.regular}
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                  }}
                >
                  CART VALUE:
                </Typography>
                <Typography
                  fontWeight={theme.fontWeight.semiBold}
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                  }}
                >
                  &#8377;
                  {Number(parseFloat(totalCost)).toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              width="max-content"
              maxWidth="100%"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              rowGap="20px"
              sx={{ columnGap: { xs: "10px", lg: "26px" } }}
            >
              <Button
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #D8D8D8",
                  color: "#000000",
                  fontSize: "16px",
                  fontWeight: theme.fontWeight.semiBold,
                  textAlign: "center",
                  height: "49px",
                  width: "220px",
                  textTransform: "none",
                }}
                onClick={() => {}}
              >
                CONTINUE SHOPPING
              </Button>
              {!outOfStock && (
                <Button
                  sx={{
                    ...stepperButtonStyles,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    setActiveStep((previousState) => previousState + 1);
                  }}
                >
                  NEXT
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "65%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <img alt="No product" src={no_products}></img>
        </Box>
      )}
    </>
  );
};

export default CartProductsRenderer;
