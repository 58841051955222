import React, { useState, useEffect } from "react";
import { Typography, FormGroup } from "@mui/material";
import theme from "../../theme";

const Timer = ({ delayResend = "30", onClick = () => {} }) => {
  const [delay, setDelay] = useState(+delayResend);
  const seconds = Math.floor(delay % 60);
  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  });

  return (
    <>
      {seconds !== 0 ? (
        <span>Resend OTP in {seconds} seconds</span>
      ) : (
        <FormGroup
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            "& label": {
              width: "max-content",
              marginRight: "0",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              width: "max-content",
              fontWeight: theme.fontWeight.regular,
              color: "#808080",
              cursor: "pointer",
            }}
            onClick={onClick}
          >
            Resend OTP?
          </Typography>
        </FormGroup>
      )}
    </>
  );
};

export default Timer;
