import { arrayRemove, doc, updateDoc } from "firebase/firestore"
import { db } from "../firebase"

export const removeProductIdFromUserFavoriteProducts = async ({
  productId,
  userId
}) => {
  const userRef = doc(db, "users", userId)
  await updateDoc(userRef, {
    favoriteProductIds: arrayRemove(productId),
    last_modified: new Date().toString(),
  })
}
