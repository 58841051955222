import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false
}

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderLoading(state, actions) {
      state.loading = actions.payload
    },
  }
})

export default orderSlice.reducer
export const { setOrderLoading } = orderSlice.actions
