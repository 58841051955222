import { useNavigate, useLocation } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import theme from "../../theme";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "./index.css";
import FavoriteButton from "../FavoriteButton";

const ProductCard = ({ products, setShowTrialRoom, setSelectedProduct }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleClickCard = (product) => {
    localStorage.setItem("product_code", product.product_code);
    let sPathname = "";
    if (product.category) {
      sPathname = product.category;
    } else {
      sPathname = pathname.substring(1, pathname.length - 1);
    }
    navigate({
      pathname: `/${sPathname}`,
      search: `?id=${product.id}`,
    });
  };

  const getPriceLabel = (product) => {
    let sName = "";
    if (
      product.category === "pant" ||
      pathname.substring(1, pathname.length - 1) === "pant"
    ) {
      sName = "Starting from";
    }

    return <>{sName}</>;
  };

  return (
    <>
      {products?.length > 0 &&
        products.map((product, index) => (
          <Stack
            onClick={() => {
              handleClickCard(product);
            }}
            id={product.product_code}
            key={index}
            sx={{
              width: "350px",
              height: "350px",
              borderRadius: "8px",
              border: "1px solid #e6e6e6",
              maxHeight: products.length < 3 ? "520px" : "100%",
              justifyContent: "space-between",
              cursor: "pointer",
              boxShadow: "none",
              transition: "box-shadow 0.2s linear",
              "&:hover": {
                boxShadow: "1px 5px 7px 0px rgba(175,171,171,.7)",
                border: "1px solid #e6e6e6",
              },
            }}
          >
            <Stack>
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                  position: "relative",
                  "& .product-image": {
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  },
                }}
              >
                {product?.card_image_url?.includes("http") ? (
                  <LazyLoadImage
                    className="product-image"
                    threshold={10}
                    visibleByDefault={true}
                    alt={product.product_name}
                    height="270px" //"150px"
                    src={product.card_image_url} // use normal <img> attributes as props
                    width="350px" //"160px"
                  />
                ) : (
                  <Box
                    sx={{
                      height: "270px",
                      width: "350px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        position: "absolute",
                        left: "18%",
                        top: "38%",
                      }}
                    >
                      Image not available
                    </Typography>
                  </Box>
                )}

                <FavoriteButton
                  productId={product.id}
                  isFavorite={product.isFavorite}
                />
              </Box>
              <Stack sx={{ paddingLeft: "5px", paddingRight: "5px" }}>
                <Typography
                  noWrap
                  sx={{
                    width: "290px",
                    textOverflow: "ellipsis",
                    fontSize: "16px",
                  }}
                >
                  {product.product_name}
                </Typography>
              </Stack>
            </Stack>
            <Box
              sx={{
                paddingLeft: "5px",
                paddingRight: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  alignSelf: "center",
                  fontWeight: theme.fontWeight.regular,
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  color: "#7b7b7b",
                }}
              >
                {getPriceLabel(product)} &#8377;
                {Number(parseFloat(product.price)).toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Box>
          </Stack>
        ))}
    </>
  );
};

export default trackWindowScroll(ProductCard);
