/* eslint-disable array-callback-return */
/* eslint-disable no-empty-pattern */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";

const initialState = {
  appointment: null,
  loading: false,
};

const appointmentSlice = createSlice({
  name: "appointmentSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAppointment.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAppointment.fulfilled, (state, action) => {
        state.appointment = action.payload;
        state.loading = false;
      });
  },
});

export const fetchAppointment = createAsyncThunk(
  "appointment/fetchAppointment",
  async ({ mobile }, thunkAPI) => {
    const sRef = collection(db, "appointments");
    const q = query(sRef, where("mobile", "==", mobile));
    const querySnapshot = await getDocs(q);

    let aAppointments = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      doc.data().status.map((oStatus) => {
        if (oStatus.id === "Completed" && oStatus.created_on === "") {
          aAppointments.push(doc.data());
        }
      });
    });

    return aAppointments;
  }
);

export default appointmentSlice.reducer;
export const {} = appointmentSlice.actions;
