import { combineReducers, configureStore } from "@reduxjs/toolkit"
import userReducer from "../features/user/userSlice"
import currentUserReducer from "../features/currentUser/currentUserSlice"
import productsReducer from "../features/products/productsSlice"
import productReducer from "../features/product/productSlice"
import orderReducer from "../features/order/orderSlice"
import ordersReducer from "../features/orders/ordersSlice"
import appointmentReducer from "../features/appointment/appointmentSlice"
import { useDispatch } from "react-redux"

const rootReducer = combineReducers({
  user: userReducer,
  currentUser: currentUserReducer,
  products: productsReducer,
  product: productReducer,
  order: orderReducer,
  orders: ordersReducer,
  appointment: appointmentReducer,
})

export const setupStore = preloadedState => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}

export const useAppDispatch = useDispatch // Export
