/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAppDispatch } from "../../app/store";
import { auth as firebaseAuth, db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { setUser } from "../../features/user/userSlice";
import { useSelector } from "react-redux";
import {
  setCurrentUser,
  setCurrentUserLoading,
} from "../../features/currentUser/currentUserSlice";
import { auth } from "../../firebase";

const Subscribe = () => {
  const dispatch = useAppDispatch();
  const currentUser = useSelector((state) => state.currentUser.currentUser);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((current) => {
      dispatch(setCurrentUserLoading(true));
      if (current) {
        if (current?.phoneNumber) {
          const currentUserData = {
            uid: current.uid,
            displayName: current.displayName,
            mobile: current.phoneNumber,
          };
          dispatch(setCurrentUser(currentUserData));
        } else {
          auth.signOut();
        }
      } else {
        dispatch(setCurrentUser(null));
      }
      dispatch(setCurrentUserLoading(false));
    });
  }, []);

  useEffect(() => {
    if (currentUser?.mobile) {
      onSnapshot(doc(db, "users", currentUser.mobile), (doc) => {
        let user = doc.data();
        dispatch(setUser(user));
      });
    } else {
      dispatch(setUser(null));
    }
  }, [currentUser]);

  return null;
};

export default Subscribe;
