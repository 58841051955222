import { arrayUnion, doc, updateDoc } from "firebase/firestore"
import { db } from "../firebase"

export const addProductIdIntoUserFavoriteProducts = async ({
  productId,
  userId
}) => {
  const userRef = doc(db, "users", userId)
  await updateDoc(userRef, {
    favoriteProductIds: arrayUnion(productId),
    last_modified: new Date().toString(),
  })
}
